/*============================================================================
Get font-families from theme settings
==============================================================================*/



.layernavigation-module {
  clear: both;
  border: 2px solid #ebebeb;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 40px;
}

.layernavigation-module .panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.layered-navigation-block {
  display: none;
  background-color: black;
  height: 100%;
  opacity: 0.35;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
}

.ajax-loader {
  display: none;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
}

.layered-heading {
  margin-bottom: 39px;
  margin-top: 4px;
}

.layered-heading h2 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 9px 0;
}

.layer-category .list-group-item {
  border: 0 !important;
}

.layer-category .list-group a {
  display: inline-block;
}

.filter-attribute-container.filter-attribute-remove-container {
  border: 0;
  padding: 0;
  margin: 0;
}

.filter-attribute-container:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.filter-attribute-container .remove-filter {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -7px;
  font-size: 0;
  width: 15px;
  height: 15px;
  padding: 0;
  /* background: url(../../../../image/remove-icon.png) no-repeat; */
  border: 0;
}

.filter-attribute-container .remove-filter:hover {
  /* background: url(../../../../image/remove-icon.png) no-repeat; */
  opacity: 0.5;
  border: 0;
}

.filter-attribute-container .filter-clear-all {
  text-align: right;
}

.filter-attribute-container .filter-clear-all .clear-filter {
  color: #23a1d1;
  display: block;
  margin: 10px 0;
}

.filter-attribute-container .filter-clear-all .clear-filter:hover {
  color: #2a6496;
  text-decoration: underline;
}

.filter-attribute-container.filter-attribute-remove-container .list-group-item {
  margin-bottom: 0px !important;
}

.filter-attribute-container.filter-price .list-group-item {
  /* padding: 0 12px; */
  margin-bottom: 0 !important;
}

.filter-attribute-container .list-group-item {
  background: none;
  padding: 0px;
  border: none;
  position: relative;
  margin-bottom: 0;
}

.filter-attribute-container .list-group-item p {
  margin: 0;
}

.filter-attribute-container .list-group-item .filter-price .input-price {
  width: 50px;
  margin: 0 0px 0 0;
  border: none;
  background: none;
}

.filter-attribute-container .list-group-item .filter-price .input-price:hover {
  cursor: not-allowed;
}

.filter-attribute-container .list-group-item .filter-price #slider-price {
  margin: 25px 0px 0px;
}

.filter-attribute-container .list-group-item .filter-price .slider-values {
  padding: 5px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
  color: #000000;
}

.filter-attribute-container .list-group-item .filter-price .slider-values span {
  margin: 0 0 0 10px;
}

.list-group .filter-attribute-container a {}

.layernavigation-container h3 {
  margin-bottom: 30px;
}

.layered {
  margin-bottom: 0;
}

.layered .list-group .filter-attribute-container:last-child .list-group-item {
  margin-bottom: 0 !important;
}

.layered .list-group {
  margin-bottom: 0px;
  webkit-box-shadow: none;
  box-shadow: none;
}

.filter-attribute-remove-container div {
  padding: 10px 0;
  font-size: 12px;
}

.filter-attribute-remove-container .filter-act-name span:hover {
  color: #e51515;
}

.filter-attribute-container {
  margin-bottom: 41px;
}

.filter-attribute-container label {
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: 500;
  display: block;
  position: relative;
  margin-bottom: 9px;
  font-family: 'Oswald', sans-serif;
}

#slider-price.ui-slider-horizontal {
  height: 5px;
}

#slider-price .ui-widget-header {
  background: #e51515;
}

#slider-price.ui-widget-content {
  background: #dbdbdb;
  border: none !important;
  border-radius: 0;
  -webkit-box-shadow: inset 0 2px 0 0 #cacaca;
  -moz-box-shadow: inset 0 2px 0 0 #cacaca;
  box-shadow: inset 0 2px 0 0 #cacaca;
}

.ui-state-default:hover,
.ui-widget-content .ui-state-default:hover,
.ui-widget-header .ui-state-default:hover {
  background: #fff;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #fff;
  border: none;
}

.ui-slider .ui-slider-handle {
  width: 13px;
  height: 13px;
  cursor: pointer;
  border-radius: 100%;
  top: -4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.ui-slider .ui-slider-handle:last-child {
  margin-left: -13px;
}

.filter-attribute-container .list-group-item a.add-filter:hover {
  color: #e51515;
}

.filter-attribute-container .list-group-item a.add-filter i {
  margin-right: 10px;
  font-size: 13px;
  display: none;
}

.filter-attribute-container .list-group-item a.add-filter,
.filter-attribute-container .list-group-item p {
  font-size: 14px;
  line-height: 26px;
  text-transform: capitalize;
  font-weight: 400;
  display: block;
  position: relative;
  padding: 12px 0;
  color: #898989;
  border-bottom: 1px solid #ebebeb;
}