html, body {
  scroll-behavior: smooth;
}
.open>.dropdown-menu {
  display: block;
}

.custom-container {
  padding: 30px 0px;
}  
@media(min-width: 768px){
  .custom-container {
    padding: 100px 0px;
  }  
}
@media(min-width: 1200px){
  .custom-container {
    padding: 150px 0px;
  } 
}

/* .open>a {
  outline: 0;
} */

.policy-term {
  float: left;
  text-align: left;
  padding: 13px 0;
  margin-left: 30px;
}
.policy-term li {
  display: inline-block;
  text-align: left;
  padding: 0px 4px;
}

.policy-term li a {
  color: #838896;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 400;
}

.policy-term li a:hover {
  color: #e51515;
}

.breadcrumb>li {
  display: inline-block;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.breadcrumb>.active {
  color: #777;
}

.sticky-top .header-inner div div div div div div div .parentMenu a {
  /* padding: 6px 18px !important; */
  /* padding: 0px 10px 0px 0px; */
  padding: 0 20px 4px !important;
  transition: all ease 0.4s;
}
.sticky-top .header-inner div div div div div div div .popup {
  top: initial !important;
}

.sticky-top .upper-header-top {
  display: none;
}

@media(min-width: 768px){
  .sticky-top .header-inner div div div div div div div .parentMenu a {
    padding: 16px 18px !important;
  }
  .pt_custommenu div.pt_menu .parentMenu a {
    padding: 29.5px 18px;
  }
}

.upper-header-top {
  position: relative;
  z-index: 9;
  background: #fff;
}

.upper-header-top ul {
  margin-left: initial !important;
  float: initial !important;
}

.content_block a {
  margin: 0px 5px;
  border: solid 1px #000;
}

.content_block a:hover {
  background-color: #fff;
}
.content_block a:hover i {
  color: #000;
}

.content_block a i {
  font-size: 18px;
  color: #fff;
}

.product-thumbnail {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
  background: #fff;
  position: relative;
}

.product-thumbnail::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  border: 1px rgba(0,0,0,0.1) solid;
  z-index: 2;
}

.product-thumbnail__quantity {
  font-size: 0.8571428571em;
  font-weight: 500;
  line-height: 1.75em;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: rgba(114,114,114,0.9);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.5833333333em;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  z-index: 3;
}

.product-thumbnail::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  border: 1px rgba(0,0,0,0.1) solid;
  z-index: 2;
}

.product__description {
  padding-left: 12px;
  padding-right: 12px;
}

.policy ol, .policy ul {
  list-style: initial;
}

.well {
  padding: 19px;
  /* margin-bottom: 20px; */
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.js-image-zoom__zoomed-image {
  z-index: 1;
}

.react-image-zoom-thumbnails div img {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.add-to-cart-button {
  position: relative;
  /* background: #e6a247 */
  /* ;
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  display: flex;
  font-family: 'Ubuntu', sans-serif;
  justify-content: space-around;
  min-width: 195px;
  overflow: hidden;
  outline: none;
  padding: 0.7rem;
  text-transform: uppercase;
  transition: 0.4s ease;
  width: auto; */
}

.add-to-cart-button.added .add-to-cart {
  display: none;
}
.add-to-cart-button.added .added-to-cart {
  display: inline-block;
}
.add-to-cart-button.added .tick {
  animation: grow 0.6s forwards;
  -webkit-animation: grow 0.6s forwards;
  animation-delay: 0.3s;
}
.added-to-cart {
  display: none;
  position: relative;
}
.tick {
  background: #146230;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: scale(0);
  z-index: 99;
}
@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes grow {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.list-group-item.active {
  background-color: #181b23;
  border-color: #181b23;
}

.submit-spinner .spinner-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(0, 0, 0, 0.14);
  z-index: 10;
}

.submit-spinner .wrapper-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.modal-title {
  font-size: 21px;
}

/* .modal-preview-img {
  position: absolute;
  top: 50%;
  z-index: 2;
} */

.modal-preview-img button {
  transform: translate(0%, -50%);
  width: 60px;
  height: 60px;
  margin: -30px 0 0;
  display: block;
  position: absolute;
  top: 50%;
  cursor: pointer;
  font-size: 26px;
  z-index: 9;
  background: rgba(255,255,255,0.4);
  border: none;
  border-radius: 100%;
  text-align: center;
  color: rgba(0,0,0,.5);
  line-height: 26px;
}

.modal-preview-img button:hover {
  background: #e51515 !important;
  color: rgba(255,255,255,0.9) !important;
}

.modal-preview-img button i {
  font-weight: bold;
}

.modal-preview-img .modal-preview-next {
  right: 15px;
}

/* large size */
@media (min-width: 768px){
  .product-modal-xl {
    max-width: 90%;
  }
}

@media (min-width: 992px){
  .product-modal-xl {
    max-width: 1000px;
  }
}

/* ori size */
@media (min-width: 768px){
  .product-modal {
    max-width: 650px;
  }
}

@media (min-width: 992px){
  .product-modal {
    max-width: 800px;
  }
}

.product-image {
  height: 260px;
  overflow: hidden;
}

.product-image img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.product-name {
  /* height: 6em; */
  line-height: 1.5em;
  /* overflow: hidden; */
}

.visible-own-buttons .owl-nav button {
  opacity: 1 !important;
  /* background: #e51515 !important;
  border-color: #e51515 !important;
  color: #fff !important; */
}

/* .shipping-form-check {
  width: max-content;
} */
.shipping-form-check label {
  cursor: pointer;
  border: solid 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shipping-form-check label:hover {
  background-color: rgb(240, 242, 245, .75);
}
.braintree-placeholder {
  margin-bottom: 0;
}

.voucher-wrapper {
  max-width: 300px;
  margin-left: auto;
  /* background-color: #181b23;
  padding: 4px;
  border-radius: 4px; */
}

.apply-btn {
  background: #e51515 !important;
}
.apply-btn:hover {
  background: #181b23 !important;
}