



/*============================================================================
Get font-families from theme settings
==============================================================================*/







/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*------------*/
body {
  font-family: "Rubik", "HelveticaNeue", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #5a6069;
  font-size: 13px;
  line-height: 1.428571429;
  width: 100%;
  position: relative;
}
.common-home {
  background: #ffffff;
}
*:focus{
  outline: none !important;
}
h1, h2, h3, h4, h5, h6 {
  color: #252525;
  line-height: 1.35;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
/* default font size */
.fa {
  font-size: 14px;
}
/* Override the bootstrap defaults */
h1 {
  font-size: 33px;
}
h2 {
  font-size: 27px;
}
h3 {
  font-size: 21px;
}
h4 {
  font-size: 15px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10.2px;
}
a {
  color: #8c8c8c;
  cursor: pointer;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
a:focus,
a:active,
a:hover {
  text-decoration: none;
  color: #e51515;
}
legend {
  font-size: 18px;
  padding: 7px 0px
}
label {
  font-size: 13px;
  font-weight: normal;
}
/* select.form-control, textarea.form-control, input[type="text"].form-control, input[type="password"].form-control, input[type="datetime"].form-control, input[type="datetime-local"].form-control, input[type="date"].form-control, input[type="month"].form-control, input[type="time"].form-control, input[type="week"].form-control, input[type="number"].form-control, input[type="email"].form-control, input[type="url"].form-control, input[type="search"].form-control, input[type="tel"].form-control, input[type="color"].form-control {
  font-size: 12px;
} */
input[type=text] {
  min-width: 50px;
}
.input-group input, .input-group select, .input-group .dropdown-menu, .input-group .popover {
  font-size: 12px;
}
.input-group .input-group-addon {
  font-size: 12px;
  height: 30px;
}
img{
  max-width: 100%; 
  height: auto;
}
/* Fix some bootstrap issues */
span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
  display: inline;
}
.flip{
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
}
div.required .control-label:before {
  content: '* ';
  color: #F00;
  font-weight: bold;
}
/* tooltip */
.tooltip.top .tooltip-arrow {
  border-top-color: #e51515;
}
.tooltip.top-left .tooltip-arrow {
  border-top-color: #e51515;
}
.tooltip.top-right .tooltip-arrow {
  border-top-color: #e51515;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #e51515;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #e51515;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #e51515;
}
.tooltip.bottom-left .tooltip-arrow {
  border-bottom-color: #e51515;
}
.tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: #e51515;
}
.tooltip-inner{
  background: #e51515;
}
.alert{
  text-align: center;
  font-size: 13px;
  margin: 0;
  border-radius: 0;
  border: none;
}
/* transition */
a:hover,.banner-3 a:hover::before,
#button-guest:hover, #button-search:hover, #button-review:hover, #button-account:hover,
#button-login:hover, #button-confirm:hover, #button-payment-method:hover, #button-register:hover,
#button-shipping-method:hover, #button-quote:hover, #button-coupon:hover,
.form-horizontal input.btn:hover, a.btn:hover, input[type='submit']:hover, .button:hover,
.product-layout:hover  .action-links-link,
.static-policy .col:hover .text-content h2, .static-policy .col:hover .col-img .hover,
.layout1:hover .btn-wishlist, .layout1:hover .btn-compare,
.banner-2 a:hover::before, .product-image:hover .img-r,
.banner-1 > div:hover img{
  -webkit-transition: ease-in-out all .3s;
  -moz-transition: ease-in-out all .3s;
  transition: ease-in-out all .3s;
}
#button-guest:hover, #button-search:hover, #button-review:hover, #button-account:hover,
#button-login:hover, #button-confirm:hover, #button-payment-method:hover, #button-register:hover,
#button-shipping-method:hover, #button-quote:hover, #button-coupon:hover,
.form-horizontal input.btn:hover, a.btn:hover, input[type='submit']:hover, .button:hover{
  color: #fff;
  background: #e51515;
}
#button-guest, #button-search, #button-review, #button-account, #button-login, #button-confirm,
#button-payment-method, #button-shipping-method, #button-quote, #button-coupon, #button-register,
.form-horizontal input.btn, a.btn, input[type='submit'], .button{
  border: none;
  background: #181b23;
  padding: 0px 30px;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 44px;
  display: inline-block;
  vertical-align: top;
  border-radius: 30px;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#button-voucher,
#button-coupon{
  height: 34px;
  line-height: 34px;
  margin-left: 10px;
  border-radius: 20px;
}
#button-search + h2{
  display: none;
}
#button-search{
  margin: 15px 0;
}
#modal-agree{
  z-index: 9999;
}
/* owl carousel */
.owl-carousel.owl-theme:hover .owl-nav button {
  opacity: 1;
}

.owl-carousel.owl-theme  .owl-dot:hover span,
.owl-carousel.owl-theme  .owl-dot.active span {
  background: #23232c;
}
.owl-carousel.owl-theme  .owl-dots {
  margin-top: 40px;
  text-align: center;
}
.owl-carousel.owl-theme  .owl-dot {
  display:inline-block;
  vertical-align:top;
  margin:5px 15px;
  position: relative;
}

.tt-product2 .owl-carousel.owl-theme  .owl-dot {
  margin:5px 10px;
}

.owl-carousel.owl-theme  .owl-dot span {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0;
  border-radius: 0;
  background: 0;
  border: 1px solid rgba(255,255,255,0.2);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tt-product2 .owl-carousel.owl-theme  .owl-dot span {
  border: 1px solid transparent;
}
.owl-carousel.owl-theme  .owl-dot span:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 7px;
  height: 7px;
  background: red;
}
.owl-carousel.owl-theme  .owl-dot.active span,.owl-carousel.owl-theme  .owl-dot:hover span{
  border-color: #e51515;
}
.tt-product2 .owl-carousel.owl-theme  .owl-dot.active span,.tt-product2 .owl-carousel.owl-theme  .owl-dot:hover span{
  background: transparent;
}
.owl-carousel.owl-theme  .owl-dot.active span:before,.owl-carousel.owl-theme  .owl-dot:hover span:before {
  background: #e51515;
}
.tt-product2 .owl-carousel.owl-theme .owl-nav {
  margin: 0;
}
.owl-carousel.owl-theme .owl-nav button {
  position: absolute;
  top: 50%;
  /* font-size: 0; */
  margin: 0;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  padding: 0;
  line-height: 46px;
  border: 2px solid #e1e1e1;
  color: #9e9e9e;
  background: #fff;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.tt-product2 .owl-carousel.owl-theme .owl-nav button {
  border: 2px solid #ebebeb;
  color: #838896;
}
.owl-carousel.owl-theme .owl-nav button:before {
  font-family: "Ionicons";
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  width: 100%;
}
.owl-carousel.owl-theme .owl-nav button.owl-prev {
  left: -65px;
}
/* .owl-carousel.owl-theme .owl-nav button.owl-prev:before {
  content: "\f124";
} */
.owl-carousel.owl-theme .owl-nav button.owl-next {
  right: -65px;
}
/* .owl-carousel.owl-theme .owl-nav button.owl-next:before {
  content: "\f125";
} */
.owl-carousel.owl-theme .owl-nav button:hover {
  background: #e51515;
  border-color: #e51515;
  color: #fff;
  -webkit-box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
}
/* .tt-product2 .owl-carousel.owl-theme .owl-nav button:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
} */
/*--------------*/
/* newsletter popup */
.b-close{
  display: block;
}
.newletter-popup{
  background: #fff;
  top: 50% !important;
  position: fixed !important;
  padding: 0;
  text-align: center;
  -moz-transform:  translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}
#boxes .newletter-title h2{
  font-size: 24px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 0 15px;
}
.newletter-popup #dialog {
  padding: 50px 20px;
}
#boxes .box-content label{
  font-weight: 400;
  max-width: 650px;
}
#boxes  #frm_subscribe .required{
  color: #f00;
  padding-right: 5px;
}
#boxes  #frm_subscribe a{
  cursor: pointer;
}
#boxes  #frm_subscribe #subscribe_pemail{
  background: #EBEBEB none repeat scroll 0% 0%;
  border: medium none;
  height: 40px;
  width: 65%;
  margin: 20px 0;
  padding:0 15px;
}
#boxes .box-content .subscribe-bottom{
  margin-top: 20px;
}
#boxes .box-content .subscribe-bottom  label{
  margin: 0;
}
#boxes .box-content .subscribe-bottom #newsletter_popup_dont_show_again{
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  margin-top: -1px;
}
#boxes  #frm_subscribe #notification{
  color: #f00;
}
#boxes  #frm_subscribe #notification .success{
  color: #67D7BB;
}
#popup2{
  text-align: center;
  background: #333;
  border-radius: 0;
  cursor: pointer;
  float: right;
  padding: 5px 10px;
  color: #fff;
  margin-top: -49px;
  margin-right: -19px;
}
#popup2:hover {
  background:#e51515;
}
/* newsllter */
.newletter-subscribe-container {
  text-align: center;
  margin-top: 85px;
  padding: 100px 0;
  border-top: 1px solid #21252c;
}
.f2 .newletter-subscribe-container {	
  border-top: 1px solid #ebebeb;
}
.newletter-subscribe-container .container-inner {

}
.newletter-subscribe-container .container-inner img.icon-newletter {
  position: absolute;
  bottom:4px;
  left:-110px;
}
#dialog-normal .newletter-title {display: block;}

#dialog-normal .newletter-title label {
  color: #838896;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}
#dialog-normal .newletter-title label span {
  color: #e51515;
}
#dialog-normal .newletter-title h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 0;
  color: #fff;
}
.f2 #dialog-normal .newletter-title h4 {
  color: #181b23;
}
#dialog-normal .newletter-title p{
  display: block;
  margin: 0;
}
#dialog-normal .newleter-content{display: block;margin-top: 55px;position: relative;}
#subscribe {
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
#subscribe_email, .subscribe_email {
  display: inline-block;
  background: #0f0f15;
  padding: 10px 70px 10px 25px;
  border: 0;
  border-bottom: 1px solid #1c1d25;
  line-height: 16px;
  height: 56px;
  border-radius: 30px;
  color: #5a6069;
  font-size: 12px;
  width: 620px;
  font-weight: 400;
}
.f2 #subscribe_email, .f2 .subscribe_email{
  background: #efefef;
  border-bottom: 0px;	
}
#subscribe .btn:hover{
  opacity: 0.8;
}
.f2 #subscribe .btn:hover{
  opacity: 0.6;
}
#subscribe .btn {
  position: absolute;
  top: 50%;
  right: 7px;
  text-transform: uppercase;
  line-height: 46px;
  height: 46px;
  width: 46px;
  padding: 0;
  font-size: 0;
  font-weight: 500;
  text-align: center;
  background: #e51515;
  color: #fff;
  -webkit-box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  border-radius: 100%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.f2 #subscribe .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
}
#subscribe .btn:before {
  content: "\f105";
  font-family: 'FontAwesome';
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
#notification-normal{
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
}

#notification-normal div.warning{
  color: red;
  text-align: center
}
/* Header */
/* header {
  margin-bottom: 100px;
}
.common-home header {
  margin-bottom: 0;
}
.common-home header.hd2 {
  position: relative;
}
.common-home header.hd2 .header-top {
  min-height: 0;
  background: none;
}
.common-home header.hd2 .header-inner {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.common-home header.hd2 .header-inner:after {
  opacity: 0;
}
.common-home header.hd2 #search input {
  background: transparent;
}
.common-home header.hd2 #logo {
  padding: 60px 0;
}
.common-home .header-top, .common-home .hd3 .header-top {
  min-height: 0;
  background: none;
}
.header-top {
  position: relative;
  min-height: 530px;
  background: url(../image/bkg_header_2.jpg) center repeat-x;
} */
.header-inner {
  text-align: center;
  padding: 0 60px;
  background: #22232b;
  position: relative;
  z-index: 10;
}
.hd3 .header-inner {
  text-align: center;
  padding: 0px;
  z-index: 10;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  /* border-bottom: 2px solid #fff; */
}
.header-inner:after {
  content: "";
  position: absolute;
  left: 0;
  right:0;
  bottom: 0;
  height: 2px;
  background: #e51515;
  z-index: -1;
}
.hd3 #middle {
  position: relative;
  z-index: 2;
}
.header-inner .container-inner {
  position: relative;
}
header .box {
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

header .box-left {
  left: 0;
  right: 0;
}
header.hd3 .box-left {
  left: 0;
  right: inherit;
}
header .box-right {
  right: 14px;
}
header .box-setting {
  float: left;
  margin: 0 10px;
}
/* .setting-button,#cart > button {
  font-size: 0;
  color: #fff;
  width: 40px;
  border: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  box-shadow: none !important;
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon_setting.png?6719724473154133038) center no-repeat;
}
.setting-button:hover {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon_setting_hover.png?6719724473154133038) center no-repeat;
} */
/* #cart button {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon_cart.png?6719724473154133038) center no-repeat;
} */
/* #cart > button:hover {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon_cart_hover.png?6719724473154133038) center no-repeat;
} */
header .setting-content .text-ex,header .setting-content  .btn-link-current{
  margin-right: 9px;
  color: #292929;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 25px;
  padding: 0;
  font-size: 12px;
  border: 0;
  pointer-events: none;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
header .setting-content .text-ex {
  margin-right: 5px;
}
header .setting-content > div {
  margin-bottom: 14px;
}
header .setting-content > div:last-child {
  margin-bottom: 0;
}
header .setting-content .content {
  border-top: 1px solid #ebebeb;
  margin-top: 3px;
  padding-top: 4px;
  padding-left: 20px;
}
header .btn-group .dropdown-menu{
  position: absolute;
  right: 0;
  float: none;
  width: 280px;
  padding:35px 40px 32px 40px;
  left: auto;
  text-align: left;
  font-size: 13px;
  margin: 10px 0 0 0;
  background: white;
  border-radius: 3px;
  border: none;
  -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 50px 0 rgba(0,0,0,0.1);
}
header .btn-group header-links {
  left: auto;
  right: -15px;
}
header .setting-content .btn-group .dropdown-menu li {padding-left: 20px;}
header .btn-group .dropdown-menu li:last-child {
  border:0;
}
header .btn-group .dropdown-menu li > a,
header .btn-group .dropdown-menu li> button,
header.hd3 .btn-group .dropdown-menu li > a,
header.hd3 .btn-group .dropdown-menu li> button{
  font-size: 13px;
  padding: 3px 0;
  line-height: 24px;
  display: block;
  text-transform: capitalize;
  font-weight: normal;
  height: inherit;
  border-radius: 0;
  text-align: left;
  color: #6b6b6b;
}
header .btn-group .dropdown-menu li > a:hover,
header .btn-group .dropdown-menu li> button:hover {
  text-decoration: none;
  color: #e51515;
}
header .btn-group .dropdown-menu li a img,
header .btn-group .dropdown-menu li button img{
  vertical-align: baseline;
  margin-right:5px;
}
header .btn-group .dropdown-menu > a:hover,
header .btn-group .dropdown-menu > button:hover,
header .btn-group .dropdown-menu .item-selected{
  color: #e51515;
  text-decoration: none;
}
.logo-container {display: inline-block;vertical-align: top;float: left;max-width: 250px;position: relative;z-index: 1;}
.hd3 .logo-container {float: none;}

#logo{
  padding: 28px 0;
}
.hd3 #logo{
  padding: 60px 0;
}
#logo a{
  display: inline-block;
}
.headerSpace {
  display: none;
}

.hd3 .switch {
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
}
.hd3 .switch:last-child {
  margin-right: 0;
}
.hd3 .switch .text-ex {
  display: none;
}
.hd3 .switch .btn-group .dropdown-menu {
  right: auto;
  left: 0;
  width: auto;
  padding: 15px 20px;
  /* min-width: auto; */
}
.hd3 .switch .btn-link {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  border: 0;
  padding: 10px 0;
  text-decoration: none;
}
.hd3 .switch .btn-link:hover {
  color: #e51515;
}

/* Search */
.search-button {
  font-size: 15px;
  color: #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  cursor: pointer;
  box-shadow: none !important;
}
.hd3 .search-button {
  display:none;
}
.search-button:before {
  content: "\f002";
  font-family: 'FontAwesome';
}
.search-button[aria-expanded="true"]:before {
  content: "\f00d";
}
.search-button:hover {
  color: #e51515 !Important;
}
#search .search-content {
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  box-shadow: none;
  right: 100%;
  width: auto;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.hd3 #search .search-content {
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  box-shadow: none;
  right: initial;
  top: initial;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  -moz-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%); 
}
#search {
  float: left;
}
.hd3 #search {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 112;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#search input {
  height: 35px;
  font-size: 12px;
  color: #5a6069;
  width: 250px;
  font-weight: 400;
  background: #22232b;
  border: 0;
  border-radius: 0;
  box-shadow: none !important;
  padding: 0 37px 0 15px;
  position: relative;
}

.hd3 #search input {
  color: #dfdfdf;
  background: transparent;
}
#search .search-content:before {
  content: "";
  position: absolute;
  height: 25px;
  width: 2px;
  background: #e51515;
  left: 0;
  top: 0;
  bottom: 0;
  margin:auto;
  z-index: 8;
}
#search .input-group-btn {
  position: absolute;
  right: 0;
  width: auto;
}
#search .input-group-btn button {
  background: transparent;
  border: 0;
  height: 35px;
  width: 35px;
  line-height: 35px;
  padding: 0;
  box-shadow: none !important;
  margin: 0;
}
#search .input-group-btn button{
  font-size: 15px;
  color: #fff;
}
#search .input-group-btn button:hover {
  color: #e51515;
}
#search .input-group-btn button:before {
  content: "\f002";
  font-family: 'FontAwesome';
}
#search ::-webkit-input-placeholder { /* Chrome */
  color: #5a6069;
}
#search :-ms-input-placeholder { /* IE 10+ */
  color: #5a6069;
}
#search ::-moz-placeholder { /* Firefox 19+ */
  color: #5a6069;
  opacity: 1;
}
#search :-moz-placeholder { /* Firefox 4 - 18 */
  color: #5a6069;
  opacity: 1;
}
/* search category */
.top-search {
}
#search-by-category {
  position: relative;
  width: 100%;
}
#search-by-category .search-container{
}
#search-by-category .search-container .categories-container{
  position: absolute;
  padding: 10px 0;
  left: 0;
  top: 0;
}
#search-by-category .search-container #text-search{
  padding: 0 55px 0 190px;
  height: 49px;
  border-radius: 3px;
  font-size: 12px;
  border: 0;
  z-index: 1;
  background: #fff;
  color: #c2c2c2;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#search-by-category #sp-btn-search{
  position: absolute;
  right: 0px;
  top: 0;
}
#search-by-category .search-container .categories-container .hover-cate p{
  margin:0;
  color: #363f4d;
  padding: 0 30px 0 25px;
  cursor: pointer;
  overflow: hidden;
  position:relative;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 29px;
}
#search-by-category .search-container .categories-container .hover-cate p:after {
  content:"";
  position: absolute;
  width: 1px;
  height: 16px;
  background: #e1e1e1;
  right: 0;
  top: 50%;
  -moz-transform:  translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#search-by-category .search-container .categories-container .hover-cate p i{display: inline-block;vertical-align: top;font-size: inherit;line-height: inherit;margin-left: 19px;}
#search-by-category .search-container .categories-container .hover-cate .cate-items{
  z-index: 9999 !important;
  position: absolute;
  display: none;
  background: #FFF;
  max-height: 300px;
  overflow: auto;
  margin-top: 0px;
  border: 1px solid #ddd!important;
  padding: 5px;
  width:100%;
  cursor: pointer;
  top: 100%;
  text-align: left;
}
.cate-items li {
  text-transform: capitalize;
  color: #363f4d;
  font-size: 11px;
  list-style: none;
  line-height: 18px;
}
.cate-items li:hover{
  color:#e51515
}
.cate-items li.f1 {
  padding-left: 5px;
}
.cate-items li.f2 {
  padding-left: 15px;
}
#search-by-category .search-ajax{
  position: absolute;
  z-index: 9999 !important;
  background: none repeat scroll 0% 0% #fff;
  left: 0px;
  top: 100%;
  width: 88%;
}
.ajax-result-item .product-info .detail .price-box .old-price{
  text-decoration: line-through;
  color: #acacac;
  font-weight: 400;
}
.ajax-result-item .product-info .detail .price-box p{
  display: inline-block;
}
.ajax-result-item .product-info .detail .price-box{
  font-weight: 700;
  margin: 0 0 10px 0;
}
.ajax-result-item .product-info .detail .product-name{
  margin: 10px 0;
}
#search-by-category .search-ajax li{
  list-style: none;
}
#search-by-category .search-ajax .ajax-result-container{
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
}
#search-by-category .search-ajax .ajax-result-container .ajax-result-msg{
  padding: 20px;
  margin: 0;
  color: #646464;
}
#search-by-category .search-ajax .ajax-result-container .ajax-result-list{
  display:block;
  border:1px solid #ddd;
  max-height:300px;
  overflow: auto;
  padding:10px;
}
#btn-search-category:hover{
  color: #363f4d;
}
#btn-search-category{
  background: #e51515;
  border:none!important;
  padding:0;
  box-shadow:none;
  text-shadow:none;
  border-radius: 3px;
  height: 49px;
  width: 49px;
  color: #fff;
  font-size: 0;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 49px;
}
#btn-search-category:before {
  font-family: 'Stroke-Gap-Icons';
  content: "\e6a4";
  font-size: 20px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
#wishlist-total, #link-compare {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 32px;
  font-size: 13px;
  color: #e51515;
  text-transform: capitalize;
  text-align: center;
}
#wishlist-total .icon, #link-compare .icon, #cart > button .icon {
  display: inline-block;
  vertical-align: top;
  position: relative;
}
#wishlist-total .text, #link-compare .text, #cart .text {
  display: none;
}
#wishlist-total:hover, #link-compare:hover,#cart > button:hover {
  color: #363f4d;
}
#link-compare span {
  display:none;
}
.search-container{}
.cart-container{
  padding: 45px 15px 0 0;
  width: 18%;
}
#wishlist-total .count,#cart-total
{
  position: absolute;
  display: block;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #333;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 84%;
  border-radius: 100%;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
}
#cart{
  width: auto;
  /* float: left; */
  display: inline-block;
}
#cart-total .total-price{
  color: #fff;
}

#cart .info-item >a {
  text-transform: uppercase;
  color: #2e2e2e;
  font-weight: 500;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
#cart .mycart {
  display: none;
}
#cart .dropdown-menu .text-empty{
  margin: 0;
  padding: 10px 0;
}

.top-menu {
  position: relative;
  z-index: 7;
  background: #e51515;
}
.hd3 .top-menu {
  position: relative;
  z-index: 1;
  background: #fff;
}
.common-home .top-menu {
  /* margin-bottom: 30px; */
}
.top-menu .col {
  float: left;
}
.top-menu .col-menu {
}
.common-home .fix-header{

}
.fix-header{
  border: none;
  background: rgba(29, 28, 28, 0.67);
  box-shadow: 0 0 5px #bdbdbd;
  -moz-box-shadow: 0 0 5px #bdbdbd;
  -webkit-box-shadow: 0 0 5px #bdbdbd;
  -o-box-shadow: 0 0 5px #bdbdbd;
  -ms-box-shadow: 0 0 5px #bdbdbd;
  width: 100%;
  max-width: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 889;
  margin: 0 auto;
  /* padding: 5px 0; */
  position: fixed !important;
  -webkit-animation-name: fadeInDown;
  -webkit-animation-duration: 5.0s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  -webkit-animation-play-state: running;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-name: anim_titles;
  -moz-animation-duration: 5.0s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: normal;
  -moz-animation-play-state: running;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -webkit-animation-duration: 0.7s;
  -moz-animation-duration: 0.7s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
}
header.hd2 .fix-header{
  border: none;
  background: rgba(29, 28, 28, 0.67) !important;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
}
header.hd3 .fix-header{
  border: none;
  border-bottom: 1px solid rgba(255,255,255,0.1); 
  width: 100%;
}
.fix-header #logo {

}
header.hd2 .fix-header #logo {
  padding: 15px 0 !important;
}
header.hd3 .fix-header #logo {
  padding: 5px 0;
}
.fix-header #logo img {
  max-height: 40px;
}
.fix-header #pt_custommenu::after, 
.fix-header #pt_custommenu::before{
  display: none;
}
.fix-header .pt_custommenu div.pt_menu .parentMenu a {
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
}
header.hd2 .fix-header .pt_custommenu div.pt_menu .parentMenu a {
  padding-top: 16px;
  padding-bottom: 17px;
}
.fix-header #pt_custommenu{
  margin: 0;
}
.fix-header .cart-container,
.fix-header .search-container,
.fix-header .logo-container{
  /* display: none; */
}
.fix-header .vermagemenu-container{
  display: none;
}
body:not(.common-home) .vermagemenu-container .vermagemenu{
  position: static;
  margin-bottom: 50px;
}
body:not(.common-home) .vermagemenu-container{
  width: 100%;
  padding: 0;
}
body:not(.common-home) header:not(.fix-header) .hozmenu-container{
  width: 100%;
}
/* cms block */
.banner-static {
  text-align: center;
  clear: both;
  /* overflow: hidden; */
}
.banner-static .owl-pagination {
  position:absolute;
  left:0;
  right:0;
  margin: auto;
  bottom: 2%;
}
.banner-static .image a {
  position: relative;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  vertical-align: top;
}

.banner-static .image a .text {
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner-static .image a .text .text1,
.banner-static .image a .text .text2,
.banner-static .image a .text .text3,
.banner-static .image a .text .link{
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.banner-static .image a:hover .text .text1 {
  -webkit-animation: fadeInUp 400ms ease-in-out;
  -moz-animation: fadeInUp 400ms ease-in-out;
  -ms-animation: fadeInUp 400ms ease-in-out;
  animation: fadeInUp 400ms ease-in-out;
}
.banner-static .image a:hover .text .text2 {
  -webkit-animation: fadeInUp 600ms ease-in-out;
  -moz-animation: fadeInUp 600ms ease-in-out;
  -ms-animation: fadeInUp 600ms ease-in-out;
  animation: fadeInUp 600ms ease-in-out;
}
.banner-static .image a:hover .text .text3 {
  -webkit-animation: fadeInUp 800ms ease-in-out;
  -moz-animation: fadeInUp 800ms ease-in-out;
  -ms-animation: fadeInUp 800ms ease-in-out;
  animation: fadeInUp 800ms ease-in-out;
}
.banner-static .image a:hover .text .link {
  -webkit-animation: fadeInUp 1000ms ease-in-out;
  -moz-animation: fadeInUp 1000ms ease-in-out;
  -ms-animation: fadeInUp 1000ms ease-in-out;
  animation: fadeInUp 1000ms ease-in-out;
}
.static-menu-bottom {
  padding: 30px 30px 5px 30px;
}
.static-sidebar a {
  border-radius: 3px;
}
.static-sidebar a img:hover {
  opacity: 0.8;
}
/*---*/
.static-welcome {
  text-align: center;
  overflow: hidden;
  margin-top: 84px;
}
.static-welcome .title {
  margin-bottom: 88px;
}
.static-welcome .title h2 {
  font-size: 100px;
  color: #5a6069;
  opacity: 0.1;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
}
.static-welcome .title h3 {
  font-size: 36px;
  /* color: #fff; */
  color: #181b23;
  font-weight: 200;
  text-transform: uppercase;
  line-height: 1;
  margin: -76px 0 0 0;
}
.static-welcome .title h3 strong {
  font-weight: 600;
  display: block;
}
.static-welcome .content {

}
.static-welcome .content .image {
  float: left;
  text-align: right;
  width: 50%;
  padding-right: 30px;
}
.static-welcome .content .text {
  overflow: hidden;
  text-align:left;
  max-width: 516px;
  padding-left: 30px;
  padding-right: 15px;
}
.static-welcome .content .text h3 {
  margin: 0 0 30px 0;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 200;
  line-height: 1.1;
  color: #9d9d9d;
}
.static-welcome .content .text h3 span {
  display: block;
}
.static-welcome .content .text h3 span.word2 {
  font-size: 30px;
  color: #e51515;
  font-weight: 600;
}
.static-welcome .content .text h3 span.word3 {
  font-size: 12px;
  letter-spacing: 5px
}
.static-welcome .content .text p {
  line-height: 24px;
  font-size: 13px;
  margin-bottom: 35px;
}
.static-welcome .author .name {
  font-weight: 200;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  display: block;
  margin-bottom: 32px;
  margin-top: 0;
}
/*---*/
.static-top-aero1 {
  margin-top: 60px;
}
.static-top-aero1 .col {
  margin-top: 30px;
}
.static-top-aero1 a {
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.static-top-aero1 .text {
  line-height: 1;
}
.static-top-aero1 .text .text1 {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;	
}
.static-top-aero1 .text .text2 {
  display: block;
  color: #ffde00;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
}
.static-top-aero1 .text .text3 {
  display: block;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 25px;
}
/*---*/
.static-bottom-aero1 {
  margin-top: 70px;
}
.static-bottom-aero1 a,.static-bottom-aero1 img {
  width: 100%;
}
.static-bottom-aero1 a,.static-bottom-aero1.alt img {
  border-radius: 3px;
}
.static-bottom-aero1 .col {
  float: left;
  width: 50%;
  margin-top: 30px;
}
.static-bottom-aero1 .text {
  padding: 0 10px;
  max-width: 620px;
  margin: auto;
}
.static-bottom-aero1 .text .text1 {
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  display: block;
  font-weight: 500;
}
.static-bottom-aero1 .text .text2 {
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
  display: block;
  font-weight: 500;
  letter-spacing: 5px;
  margin-top: 3px;
}
.static-bottom-aero1.alt .text .text2 {
  font-size: 24px;
}
.static-bottom-aero1 .text .text3 {
  font-size: 14px;
  color: #fff;
  display: block;
  line-height: 24px;
  font-family: "Rubik", "HelveticaNeue", "Helvetica Neue", sans-serif;
  margin-top: 20px;
}
.static-bottom-aero1 .text .link {
  font-size: 12px;
  color: #383838;
  text-transform: uppercase;
  background: #fff;
  display: inline-block;
  vertical-align: top;
  line-height: 46px;
  padding: 0 25px;
  padding-right: 60px;
  border-radius: 30px;
  font-weight: 500;
  position: relative;
  margin-top: 52px;
}
.static-bottom-aero1 .text .link:after {
  content: "\f105";
  font-family: 'FontAwesome';
  font-size: 16px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #383838;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  right: 4px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.static-bottom-aero1 .text .link:hover {
  background:#e51515;
  color: #fff;
  -webkit-box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
}
.static-bottom-aero1 .text .link:hover:after {
  background:#fff;
  color: #303030;
}
/* Products */
/*-product in common-home-*/
/* .common-home .item .item-inner {
  border: 2px solid #21252c;
}
.common-home .product-name a {
  color: #838896;
}
.common-home .item-inner .ratings {
  border-bottom: 1px solid #1e2229;
}
.common-home .item-inner .product-name {
  border-bottom: 1px solid #1e2229;
}
.common-home .item-inner  button.btn-cart {
  background: #21252c;
}
.common-home .item-inner  button.btn-cart:hover {
  background:#e51515;
  color: #fff;
  -webkit-box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
}
.common-home .item-inner .product-des {
  display:none;
}
.common-home .large-product .item-inner .product-des {

  border-bottom: 1px solid #1e2229;
}

.common-home .tt-product2 .item .item-inner {
  border: 2px solid #efefef;
}
.common-home .tt-product2 .product-name a {
  color: #181b23;
}
.common-home .tt-product2 .item-inner .ratings {
  border-bottom: 1px solid #efefef;
}
.common-home .tt-product2 .item-inner .product-name {
  border-bottom: 1px solid #efefef;
}
.common-home .tt-product2 .item-inner  button.btn-cart {
  background: #efefef;
}
.common-home .tt-product2 .item-inner  button.btn-cart:hover {
  background:#e51515;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.common-home .tt-product2 .item-inner .product-des {
  display:none;
}
.common-home .tt-product2 .large-product .item-inner .product-des {

  border-bottom: 1px solid #efefef;
} */
/*---*/ 
.owl-item.active .item {
  -moz-animation: zoomIn 500ms ease;
  -o-animation: zoomIn 500ms ease;
  -webkit-animation: zoomIn 500ms ease;
  animation: zoomIn 500ms ease;
}
.item .item-inner {
  position: relative;
  border: 2px solid #efefef;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.product-col {
  display: flex;
  flex-direction: column;
  min-height: 515px;
}
.label-product {
  color: #fff;
  background:url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/bkg_label_new.png?6719724473154133038) center no-repeat;
  line-height: 22px;
  padding-left: 5px;
  min-width: 56px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 41px;
  right: 14px;
  text-transform: uppercase;
  font-size: 10px;
  z-index: 3;
}
.label_sale {
  background:url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/bkg_label_sale.png?6719724473154133038) center no-repeat;
  top: 15px;
}
.item-inner .images-container {
  position: relative;
  background: #fff;
  text-align: center;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.item-inner .images-container .product-image {
  position:relative;
  display: block;
  text-align: center;
}
.item-inner .images-container img {
  margin: auto;
}
.item-inner .images-container .img-r {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  opacity:0;
  visibility:hidden;
  z-index:1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.item-inner:hover .images-container .img-r {
  opacity:1;
  visibility: visible;
}
.item-inner  button.btn-quickview {
  line-height: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  background: transparent;
  text-transform: capitalize;
  color: #363c45;
  font-weight: normal;
  letter-spacing: 0;
  font-size: 0;
  text-align: center;
  border: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.item-inner  button.btn-quickview:before {
  content: "\f06e";
  font-family: "FontAwesome";
  font-size: 20px;
  display: inline-block;
  vertical-align: top;
  line-height: inherit;
  width: 100%;
}
.qv-button-container {
  display: block;
}
.tt-product {
}
.item-inner  .action-links button {
  display: block;
  margin-bottom: 6px;
}

.item-inner  .action-links button:hover {
  color: #e51515;
  background: transparent;
}
.item-inner  .action-links button:last-child {
  margin-bottom: 0;
}
.item-inner  button.btn-cart {
  background: #efefef;
  color: #6c6f7a;
  text-transform: uppercase;
  line-height: 44px;
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 22px;
}
.item-inner  button.btn-cart:before {
  content: "";
  margin-right: 8px;
  margin-top: -3px;
  width: 16px;
  height: 16px;
  background:url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon-btn-cart.png?6719724473154133038) center no-repeat;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.item-inner  button.btn-cart:hover {
  background:#e51515;
  color: #fff;
}
.item-inner  button.btn-cart:hover:before {
  background:url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon-btn-cart-hover.png?6719724473154133038) center no-repeat;
}
button.btn-compare span, 
button.btn-wishlist span{
  display: none;
} 
button.btn-compare i, 
button.btn-wishlist i{
  display: none;
} 
button.btn-compare, 
button.btn-wishlist {
  line-height: 30px;
  height: 30px;
  width: 30px;
  display: block;
  background: transparent;
  font-weight: normal;
  font-size: 0;
  padding: 0;
  text-align: center;
  color: #363c45;
}
button.btn-compare:before, 
button.btn-wishlist:before {
  content: "\f08a";
  font-family: "FontAwesome";
  font-size: 20px;
  display: inline-block;
  vertical-align: top;
  line-height: inherit;
  width: 100%;
}
button.btn-compare:before {
  content: "\f012";
}

.item-inner .caption {
  padding: 0 20px 25px 20px;
  background: transparent;
  text-align: left;
  overflow: hidden;
  position: relative;
}
.item-inner .caption-top {
  padding-top: 0;
  margin-bottom: 16px;
}
.item-inner .caption .manufacture-product {
  display: none;
}
.item-inner .product-name {
  margin-bottom: 0;
  display: block;
  margin-top: 0;
  padding-top: 14px;
}
.item-inner .product-code {
  padding-bottom: 14px;
  margin: 0;
  border-bottom: 1px solid #efefef;
}
.item-inner .product-des {
  line-height: 24px;
  letter-spacing: 0.25px;
  border-bottom: 1px solid #efefef;
  padding: 14px 0;
}
.best-product .product-name a {
  color: #8c8c8c;
  padding: 0;
  display: block;
  text-transform: uppercase;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
}
.product-name a {
  color: #181b23;
  padding: 0;
  display: block;
  text-transform: uppercase;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
}
.product-name a:hover {
  color: #e51515;
}
.item-inner .ratings {
  padding: 13px 0;
  border-bottom: 1px solid #efefef;
}
.ratings {
  display:block;
}
.ratings > span {
  text-transform: capitalize;
  margin-right: 10px;
  color: #5a6069;
  font-size: 13px;
}
.ratings a {
  font-size: 12px;
  color: #adadad;
  line-height: 1;
  margin-left: 10px;
}
.ratings a:hover {
  color: #e51515;
}
.ratings a.review-count {
  margin-right: 10px;
  margin-left: 20px;
}
.ratings .rating-box {
  display: inline-block;
  vertical-align:top;
  margin-top: 2px;
  color: #adadad;
}
.rating-box div[class^='rating']{
  /* background: url('../image/rating0.png') no-repeat; */
  font-size: 0;
  height: 12px;
  width: 72px;
}
.rating-box div.rating1{
  /* background-image: url('../image/rating1.png'); */
}
.rating-box div.rating2{
  /* background-image: url('../image/rating2.png'); */
}
.rating-box div.rating3{
  /* background-image: url('../image/rating3.png'); */
}
.rating-box div.rating4{
  /* background-image: url('../image/rating4.png'); */
}
.rating-box div.rating5{
  /* background-image: url('../image/rating5.png'); */
}
.price-box p {
  display: inline-block;
  vertical-align:top;
  margin-bottom: 0;
}
.price-box .price-tax {
  display: none;
}
.price-box .price {
  color: #e51515;
  font-size: 18px;
  font-weight: 500;
}
.price-box p.special-price .price{
  color: #e51515;
  font-weight: 500;
}
.price-box p.old-price {
  margin-left: 5px;
}
.price-box p.old-price .price {
  text-decoration: line-through;
  font-weight: 200;
  color: #606161;
}
.rate-special {
  color: #30343c;
  background: #ffcd05;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
  border-radius: 2px;
  padding: 0 5px;
  position: relative;
  margin: 16px 6px 0 0;
}
.rate-special:after {
  content: "";
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid #ffcd05;
  position: absolute;
  right: -8px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.item-inner .caption .price-box {
  margin-top: 16px;
  display: inline-block;
  vertical-align: top;
  padding: 2.5px 0;
}
.price-box {
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.item-inner .caption .price-box label {
  margin:0;
  font-size: 13px;
  display: none;
  color: #363f4d;
}
/* module title*/
/* .common-home .module-title h2 {
  background: #14151b;
  color: #fff;
}
.common-home .module-title h2:before,.common-home .module-title h2:after  {
  background: #14151b;
}
.common-home .module-title.title2 h2 {
  background: #fff;
  color: #181b23;
}
.common-home .module-title.title2 h2:before,.common-home .module-title.title2 h2:after  {
  background: #fff;
} */
.module-title {
  text-align: center;
  position: relative;
  margin-top: 100px;
  margin-bottom: 17px;
}
.module-title:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 9px;
  width: 50%;
  border-top: 1px solid #363c45;
  border-bottom: 1px solid #363c45;
}
.module-title:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  width: 70%;
  background: #363c45;
}

.module-title h2 {
  margin: 0 auto;
  z-index: 2;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #181b23;
  line-height: 62px;
  padding: 0px 63px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: inset 0 3px 0 0 #363c45, inset 0 -3px 0 0 #363c45;
  -moz-box-shadow: inset 0 3px 0 0 #363c45,inset 0 -3px 0 0 #363c45;
  box-shadow: inset 0 3px 0 0 #363c45, inset 0 -3px 0 0 #363c45;
}
.module-title h2:before,.module-title h2:after  {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  background: #fff;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: -63px;
  margin: auto 0;
  border: 4px solid #363c45;
  -webkit-transform: rotate(45deg) skew(26deg,26deg);
  -moz-transform: rotate(45deg) skew(26deg,26deg);
  -ms-transform: rotate(45deg) skew(26deg,26deg);
  -o-transform: rotate(45deg) skew(26deg,26deg);
  transform: rotate(45deg) skew(26deg,26deg);
}
.module-title h2:after {
  left:auto;
  right: -63px;
}
.module-title h2 span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 30px;
}
.module-title h2 span:before {
  content: "\f005";
  font-size: 14px;
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.module-title h2 span:after {
  content: "\f005";
  font-size: 14px;
  font-family: "FontAwesome";
  position: absolute;
  right: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.module-description {
  line-height: 24px;
  text-align: center;
  margin-bottom: 7px;
  margin-top: 0;
}
.module-description p {
  margin: 0;
}
/* grid-style */
.tt-product .product-layout.grid-style{
  margin-top: 45px;
}

.grid-style .item-inner  .action-links {
  position: absolute;
  top: 14px;
  left: 10px;
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.grid-style .item-inner:hover  .action-links {
  left: 18px;
  opacity: 1;
}
/* list-style*/
.list-style {
  border:0;
  padding:0;
  margin-top:0;
}
.list-style .module-title {
}
.list-style .label-pro-new,.list-style .label-pro-sale {
  right:0;
}
.list-style .item {
}
.list-style .item .item-inner:before {
  display: none;
}
.list-style .item .item-inner {
  margin:0;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.ocbestsellerproducts .list-style .item .item-inner {
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius:0;
}
.list-style .item .item-inner .images-container {
  float: left;
  width: 31.5%;
  padding: 0;
}
.ocbestsellerproducts .list-style .item .item-inner .images-container { 
  width: 32.435%;
  border-radius: 10px;
}
.list-style .item .item-inner .caption {
  text-align: left;
  padding-left: 15px;
  padding-right: 0;
  overflow: hidden;
  padding-top: 11px;
}
.ocbestsellerproducts .list-style .item .item-inner .caption {
  padding-top: 8px;
  padding-bottom: 0;
}

.ocbestsellerproducts .list-style .item-inner .ratings,.list-style .product-name {
  border:0;
  padding: 0;
}
.ocbestsellerproducts .list-style .product-name {
  margin-top: 7px;
  margin-bottom: 17px;
  border-bottom: 0px; 
}
.list-style .product-name {
}
.ocbestsellerproducts.list-style .product-name a {
  color: #838896;
}
.ocbestsellerproducts .list-style .product-name a:hover {
  color: #e51515;
}
.list-style .item-inner .price-box {
  margin-top: 15px; 
}
.list-style .item-inner  .action-links {
  margin-top: 58px;
}
.list-style.product-layout {
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px dotted #ebebeb;
}
.ocbestsellerproducts .list-style.product-layout {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(255,255,255,0.1) ;
}
.list-style.product-layout:last-child {
  border-bottom: 0;
  margin: 0;
  padding: 0;
}
.bestseller-full .owl-item.active:before {
  content: "";
  width: 1px;
  height: 100%;
  background: rgba(255,255,255,0.1);
  left: -15px;
  top: 0;
  bottom: 0;
  position: absolute;

}
.bestseller-full .owl-item.active.first:before {
  display: none;
}
.bestseller-full {
  /* background: url(../image/bkg_module_list.jpg) center; */
  padding-bottom: 100px;
  margin-top: 100px;
}
.bestseller-full .tt-product {
  margin-top: 53px;
}
.bestseller-full .module-title h2 {
  background: #221e1c;
  color: #fff;
}
.bestseller-full .module-title h2:before,.bestseller-full .module-title h2:after {
  background: #221e1c;
}
.bestseller-full .owl-carousel.owl-theme  .owl-dots {
  margin-top: 58px !important;
}
/* large-product */
.large-product .item-inner .ratings {
  padding: 16px 0;
  margin-top: 5px;
}
.large-product .item-inner .product-name {
  padding: 19px 0;
  margin-top: 2px;
}
.large-product .item-inner  button.btn-cart {
  line-height: 56px;
  margin-top: 31px;
}
/* module-nav2 */
.module-nav2  .owl-carousel.owl-theme .owl-nav {
  position: absolute;
  right: -7px;
  top: -50px;
  margin: 0;
}
.module-nav2  .owl-carousel.owl-theme .owl-nav button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  opacity: 1;
  width: 20px;
  background: transparent;
  border-radius: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  margin: 0;
}
.module-nav2  .owl-carousel.owl-theme .owl-nav button:before {
  font-size: 14px;
}
.module-nav2  .owl-carousel.owl-theme .owl-nav button.disabled {
  color: #b2b2b2 !important;
}
.module-nav2  .owl-carousel.owl-theme .owl-nav button:hover {
  color: #e51515;
  background: none;
}
.module-nav2  .owl-carousel.owl-theme .owl-nav button.owl-prev {
  margin-right: 14px;
}
.module-nav2  .owl-carousel.owl-theme .owl-nav button.owl-prev:after {
  content: "";
  height: 13px;
  width: 1px;
  background: #e1e1e1;
  position: absolute;
  right: -7px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.module-nav2 .owl-carousel.owl-theme  .owl-dots {
  position: absolute;
  right: -5px;
  top: -60px;
  margin: 0;
}
/* slider-group */
.slider-group {
  margin-top: 20px;
}
/* background-full */
.background-full {
  background: #f6f6f6;
  padding-bottom: 80px; 
}
.background-full2 {
  padding-bottom: 60px;
}
/* product-tabs-category-container-slider */
.tabs-category-slider {
}
.tabs-category-slider .module-title {

}

.tabs-category-slider .tabs-categorys {
  z-index: 1;
  display: block;
  text-align: center;
  margin-top: 34px;
  margin-bottom: 3px;
}
.tabs-category-slider .tabs-categorys li {
  display: inline-block;
  vertical-align: top;
  margin-right: 66px;
  position: relative;
}
.tabs-category-slider .tabs-categorys li:last-child {
  margin-right: 0;
}
.tabs-category-slider .tabs-categorys li:after	 {
  content: "";
  width: 7px;
  height: 7px;
  background: #434449;
  position: absolute;
  right: -39px;
  top: 9px;
  transform: rotate(45deg);
}
.tabs-category-slider.tt-product2 .tabs-categorys li:after	 {
  background: #e1e1e1;
}
.tabs-category-slider .tabs-categorys li:last-child:after {
  display: none;
}
.tabs-category-slider .tabs-categorys li a {
  font-size: 14px;
  color: #838896;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 200;
  cursor: pointer;
  display: block;
}
.tabs-category-slider.tt-product2 .tabs-categorys li a {
  color: #181b23;
}
.tabs-category-slider .tabs-categorys li:last-child {
  margin:0;
}
.tabs-category-slider .tabs-categorys li a:hover,.tabs-category-slider .tabs-categorys li.active a {
  color: #ffffff;
}
.tabs-category-slider.tt-product2 .tabs-categorys li a:hover,.tabs-category-slider.tt-product2 .tabs-categorys li.active a {
  color: #e51515;
}
/* .tabs-category-slider .product-des, .related-container .product-des {
  display: none; 
} */
/* product-tabs-container-slider */
.product-tabs-container-slider .module-title h2 {
  display: none;
}
.product-tabs-container-slider .tabs {
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 17px;
}
.product-tabs-container-slider .tabs li {
  font-size: 14px;
  color: #bbbbbb;
  line-height: 26px;
  float: left;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  margin-right: 35px;
}
.product-tabs-container-slider .tabs li:last-child {
  margin:0;
}
.product-tabs-container-slider .tabs li:hover,.product-tabs-container-slider .tabs li.active {
  color: #333333;
}
.owl-demo-tabproduct .item:not(:first-child):last-child .item-inner .images-container {
  float: left;
  width: 45%;
}
.owl-demo-tabproduct .item:not(:first-child):last-child .item-inner .caption {
  overflow: hidden;
}
.owl-demo-tabproduct .item:not(:first-child):last-child .item-inner .quick-view,.owl-demo-tabproduct .item:not(:first-child):last-child .item-inner .action-links {
  display: none;
}
.owl-demo-tabproduct .item:not(:first-child):last-child .item-inner .caption .price-box {
  opacity: 1;
  visibility: visible;
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}
/* featured-categories-container */
.featured-categories-container {
  margin-bottom: 80px; 
}
.featured-categories-container .owl-item.active {
  border-right: 1px solid #ebebeb; 
}
.featured-categories-container .owl-item.active.last {
  border:none;
}
.fcategory-content .image {
  display:block;
  border-radius: 1px;
  overflow: hidden;
}
.owl-featured-categories {
  border: 1px solid #ebebeb;
  background: #fff; 
}
.fcategory-content {
  padding:20px; 
}
.fcategory-content .image img {
  -webkit-transition: all 300ms ease-in;
  -moz-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.fcategory-content .image:hover img {
  -moz-transform: scale3d(1.03, 1.03, 1.03);
  -webkit-transform: scale3d(1.03, 1.03, 1.03);
  -o-transform: scale3d(1.03, 1.03, 1.03);
  -ms-transform: scale3d(1.03, 1.03, 1.03);
  transform: scale3d(1.03, 1.03, 1.03);
}
.featured-categories-container .content-thumb .name {
  margin-top: 17px;
  margin-bottom: 16px;
}
.featured-categories-container .content-thumb .name a {
  font-size: 14px;
  color: #e51515;
  display: block;
  font-weight: 500;
}
.featured-categories-container .content-thumb .name a:hover {
  color: #e51515;
}
.sub-featured-categories li a {
  display: block;
  font-size: 13px;
  color: #7a7a7a;
  line-height: 24px;
}
.sub-featured-categories li a:hover {
  color: #e51515;
  padding-left: 7px;
}
.featured-categories-container .view-more {
  line-height: 29px;
  padding: 0 20px;
  color: #949494;
  text-transform: capitalize;
  background: #f1f1f1;
  border-radius: 3px;
  display:inline-block;
  vertical-align: top;
  margin-top: 21px;
}
.featured-categories-container .view-more:hover {
  background: #e51515;
  color: #fff;
}
/* CountDown */
.occountdown_module  {
}
.item-inner .caption .available {
  display: none;
}
.occountdown_module .item-inner .caption .available {
  font-size: 13px;
  color: #363f4d;
  margin-bottom: 0;
  margin-top: 20px;
  text-transform: capitalize; 
  display: block;
}
.occountdown_module .item-inner .caption .available span {
  color: #e51515;
  margin-left: 3px;
}
.occountdown_module .product-des {
  font-size: 13px;
  color: #7a7a7a;
  line-height: 24px;
  margin-top: 18px;
  display: block;
  margin-bottom: 25px;
}
.text-hurryup {
  float: left;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #a8a8a8;
  margin-bottom: 0;
  margin-right: 20px;
}
.text-hurryup strong {
  display: block;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 18px;
  color: #3d3d3d;
}
.is-countdown {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 2;
}
.countdown-row {
  display: block;
  margin: 0 -3.5px;
  overflow: hidden;
}
.countdown-section {
  width: 25%;
  float: left;
  padding: 0 3.5px;
}
.countdown-section .time-info {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background: #fff;
  border: 1px solid #ebebeb;
  padding: 11px 0;
  border-radius: 3px;
}
.countdown-section:last-child {
  border: 0;
}
.countdown-amount {
  font-size: 16px;
  line-height: 1;
  color: #2c2c37;
  position: relative;
  display: block;
  font-weight: 700;
  font-family: 'Karla', sans-serif;
}
.countdown-period {
  display: block;
  font-size: 12px;
  color: #c8c8c8;
}
/* oc-featured-products */
.oc-featured-products {
  margin-bottom: 80px;
}
/* testimonial */
.testimonial-container {
  /* background: url(../image/bkg_testtimor.jpg) center; */
  padding: 130px 0 143px 0;
  margin-top: 100px;
}
.testimonial-container .module-title {
  display: none;
}
.testimonial-images {
  display: block;
  text-align: center;
  padding: 30px;
  margin-bottom: 6px;
}
.testimonial-images img {
  -webkit-box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  display: inline-block;
  vertical-align: top;
  border-radius: 100%;
  margin: auto;
}
.testimonial-box{
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  max-width: 920px;
  margin: auto;
  padding: 0 80px;
  letter-spacing: 0.25px;
  position: relative;
}
.testimonial-box:before,.testimonial-box:after {
  content:"";
  width: 51px;
  height: 41px;
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon1.png?6719724473154133038);
  position: absolute;
  left: 0;
  top: 0;
}
.testimonial-box:after {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon2.png?6719724473154133038);
  left: auto;
  right: 0;
}
.testimonial-box a {
  color: #90939c;
}
.testimonial-author {
  text-transform: uppercase;
  color: #fff;
  font-weight: 200;
  text-align: center;
  margin-top: 40px;
}
.testimonial-container .owl-carousel.owl-theme  .owl-dot {
  margin: 0 75px;
}
.testimonial-container .owl-carousel.owl-theme  .owl-dot:before {
  content: '';
  display: block;
  width: 123px;
  height: 1px;
  background: rgba(255,255,255,0.1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: -137px;
}
.testimonial-container .owl-carousel.owl-theme  .owl-dot:last-child:after {
  content: '';
  display: block;
  width: 123px;
  height: 1px;
  background: rgba(255,255,255,0.1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: -137px;
}
.testimonial-container .owl-carousel.owl-theme  .owl-dots {
  margin-top: 55px;
}
.testimonial-container .owl-carousel.owl-theme  .owl-dot:before {

}
.row-testimonials {
  padding: 20px;
  border: 1px solid #ebebeb;
  margin-bottom: 30px;
  text-align: center;
}

/* corporate */
.corporate-full {
  background: #181b23;
}
.ma-corporate-about {
  text-align: left;
  margin-top: 70px;
  margin-bottom: 100px;
}
.ma-corporate-about .col {
  margin-top: 30px;
}

.ma-corporate-about .block-wrapper {
  display: inline-block;
  vertical-align: top;
  padding: 26px 30px;
  border: 1px solid #21252c;
  border-radius: 10px;
  position: relative;
}
/*.ma-corporate-about .block-wrapper:after {
content: "\e615";
font-family: 'Stroke-Gap-Icons';
font-size: 36px;
color: #e51515;
background: #181b23;
position: absolute;
right: -20px;
line-height: 66px;
width: 40px;
text-align: center;
top: 50%;
-moz-transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-o-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
}*/
.ma-corporate-about .block-wrapper i {
  content: "\e615";
  font-size: 36px;
  color: #e51515;
  background: #181b23;
  position: absolute;
  right: -20px;
  line-height: 66px;
  width: 40px;
  text-align: center;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/*.ma-corporate-about .block-wrapper.wrapper2:after {
content:"\e653";
}
.ma-corporate-about .block-wrapper.wrapper3:after {
content: "\e67f";
}*/

.ma-corporate-about .block-wrapper .text-des {
  text-align: left;
  display: block;
}
.ma-corporate-about .block-wrapper .text-des h3 {
  color: #fff;
  font-weight: 200;
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
  display: block;
  margin-bottom: 7px;
}
.ma-corporate-about .block-wrapper .text-des p {
  font-weight: 400;
  line-height: 24px;
  display: block;
  margin: 0;
}

.ma-corporate-about1 {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
  border-top: 1px solid rgba(255,255,255,0.15);
}
.ma-corporate-about1 .row {
  margin: 0;
}
.ma-corporate-about1 .col {
  padding: 0;
}
.ma-corporate-about1 .col:nth-child(2) {
  border-left: 1px solid rgba(255,255,255,0.15);
  border-right: 1px solid rgba(255,255,255,0.15);
}
.ma-corporate-about1 .block-wrapper {
  display: inline-block;
  vertical-align: top;
  padding: 29px 15px;
  border-radius: 10px;
  position: relative;
}


.ma-corporate-about1 .block-wrapper .text-des {
  text-align: center;
  display: block;
}
.ma-corporate-about1 .block-wrapper .text-des h3 {
  color: #fff;
  font-weight: 200;
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
  display: block;
  margin-bottom: 7px;
}
.ma-corporate-about1 .block-wrapper .text-des p {
  font-weight: 400;
  line-height: 18px;
  display: block;
  margin: auto;
  max-width: 240px;
  color: #6c7076;
}
/* End Corporate */
/* instagram_block_home */
#instagram_block_home {
  /* margin-top: 100px;
  margin-bottom: 100px; */
  position: relative;
}
#instagram_block_home a:hover img {
  opacity: 0.8;
}
#instagram_block_home .title_block {
  z-index: 2;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
#instagram_block_home .title_block h3 {
  line-height: 35px;
  color: #fff;
  font-size: 12px;
  background: #23232c;
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  margin: 0;
}
#instagram_block_home .title_block h3:before {
  content:"\f350";
  font-family: "Ionicons";
  font-size: 18px;
  margin-right: 5px;
  vertical-align: middle;
}
/* blog */
.menu-recent {

}
.menu-recent .articles-container {
  margin-top: 52px;
}
.menu-recent img {
  width: 100%;
}
.menu-recent .articles-inner {
  border-right: 0 !important;
  clear: both;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-bottom: 15px;
}
.menu-recent .row_items .articles-inner:not(:first-child) {
  margin-top: 30px;
}
.menu-recent .articles-image {
  position: relative;
  float: left;
  width: 47.37%;
}
.menu-recent .articles-image a:hover img {
  opacity: 0.8;
}
.menu-recent .aritcles-content {
  padding-left: 30px;
  float: left;
  width: 52.63%;
  padding-top: 35px;
}
.menu-recent .articles-image a {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  max-width: 100%;
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.articles-name {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  margin-bottom: 13px;
  margin-top: 10px;
  color: #838896;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
.blog2 .articles-name {
  color: #181b23;
}
.articles-date {
  font-size: 14px;
  text-transform: uppercase;
  font-weight:500;
  color: #e51515;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
.articles-date span {
  font-size: 36px;
}
.articles-intro p {
  line-height: 24px;
  padding: 16px 0;
  font-weight: 400;
  margin: 0;
  border-top: 1px solid #1e2229;
}
.blog2 .articles-intro p {
  border-top: 1px solid #ebebeb;
}
.menu-recent .author {
  text-transform: capitalize;
  display: block;
  margin-bottom: 19px;
  letter-spacing: 0.25px;
}
.menu-recent .author:before {
  content: "\f044";
  font-family: 'FontAwesome';
}
.menu-recent .author span {
  color: #e51515;
}
.menu-recent .read-more {
  color: #838896;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
  margin-top: 11px;
  line-height: 46px;
  padding: 0 55px;
  background: #21252c;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
.blog2.menu-recent .read-more {
  font-size: 12px;
  background: #efefef;
}
.menu-recent .read-more:hover {
  background: #e51515;
  color: #fff;
  -webkit-box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
}
.blog2.menu-recent .read-more:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.blog-blog .intro-content img,.blog-article .article-description img {
  float: left;
  margin-right: 30px;
  margin-bottom: 15px;
}
.blog-blog .article-list,.blog-article .article-container{
  clear: both;
  overflow: hidden;
  margin-bottom: 15px;
}
.article-description p,.intro-content p {
  line-height: 24px;
}
/* brand */
.brand-slider img {
  display:inline-block;
  vertical-align:top;
}
.brand-slider img:hover {
  opacity: 0.7;
}
.brand-slider {
  padding: 80px 0;
  text-align: center;
}
/* Social */
.follow {
  display: block;
  margin-top: 32px;
}
.follow label {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  margin-bottom: 8px;
}
ul.link-follow {
  display: inline-block;
  vertical-align: top;
  margin-left: 60px;
  float: left;
}
ul.link-follow li {
  float: left;
  margin-right: 10px;
  margin: 5px 0;
}
ul.link-follow li:last-child {
  margin-right: 0;
}
ul.link-follow li a {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #838896;
  font-size: 0;
  line-height: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  text-align: center;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
ul.link-follow li a:before {
  font-size: 14px;
}
ul.link-follow li a span {
  display: none;
}
ul.link-follow li a:hover {
  color: #e51515;
}
/* footer */
.common-home footer {
  margin-top: 0;
}
footer{
  /* background: url(../image/bkg_footer.jpg) center; */
  border-top: 2px solid #e51515;
  margin-top: 100px;
}
.footer-top {
  padding: 100px 0 30px 0;
}
footer .col-footer{
  margin-bottom: 30px;
  border-right: 1px solid #21252c;
}
footer .col-footer:last-child { 
  border: 0;
}
.footer-title h3{
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
  margin-top: 7px;
}
.col1 .footer-title {
  display: none;
}
.footer-content {
  line-height: 24px;
  font-weight: 400;
}
.footer-content .logo-footer {
  display: block;
  margin-bottom: 34px;
}
.footer-content .des {
  margin-bottom:0;
  letter-spacing: 0.25px;
  color: #fff;
}
.footer-contact {
  color: #fff;
  line-height: 23px;
  letter-spacing: 0.25px;
  margin-top: 40px;
}
.footer-contact li i {
  font-size: 16px;
  color: #e51515;
  margin-right: 12px;
}
.footer-tags a {
  line-height: 32px;
  display: inline-block;
  vertical-align: top;
  margin-right: 6px;
  background: #2e2e2e;
  color: #545454;
  border-radius: 3px;
  padding: 0 20px;
  margin-bottom: 15px;
}
.footer-tags a:hover {
  color: #fff;
}
.footer-contact li {
  padding: 3px 0;
}
.footer-contact li span {
  min-width: 53px;
  margin-right: 13px;
  display: inline-block;
  vertical-align: top;
}
.footer-payment {
  display:block;
  margin-top: 8px;
  float: right;
}
.footer-payment label {
  color: #363f4d;
  margin-bottom: 10px;
  display: block;
  font-size: 13px;
  font-style: italic;
}
footer .list-unstyled.text-content li a:hover{
  color: #e51515;
  padding-left: 7px;
}
footer .list-unstyled.text-content li a{color: #5a6069;display: block;font-weight: 400;padding: 6px 0;}
.footer-middle {
  background:#232f3e;
}
.footer-middle .container-inner {
  padding: 40px 0;
  border-top: 1px solid rgba(255,255,255,0.05);
}
.tag-static{
}
.tag-static li a:first-child{
  padding: 0 15px;
  font-weight: 400;
  color: #fff;
  background:#37475a;
  border-radius: 2px;
  margin-right: 8px;
}
.tag-static li a:first-child:after,.tag-static li a:last-child:after {
  display:none;
}
.tag-static li a {
  line-height: 32px;
  text-transform: capitalize;
  color: #5b697b;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.tag-static li a:after {
  content:"/";
  display: inline-block;
  vertical-align: top;
  margin:0 9px;
  color: #5b697b;
}
.tag-static li a:hover {
  color: #fff;
}
.tag-static li {
  display: block;
  margin-bottom: 8px;
}
.tag-static li:last-child {
  margin-bottom:0;
}
.footer-bottom {

}
.footer-bottom .container-inner {
  padding: 51px 0;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  border-top: 1px solid rgba(235,235,235,0.05);
  text-align: center;
}
.footer-bottom .footer-copyright {
  float: left;
  color: #838896;
  font-size: 13px;
  background: none;
  text-transform: capitalize;
  font-weight: 400;
  text-align: left;
  padding: 13px 0;
}
.footer-bottom .footer-copyright a {
  color: #e51515;
}
.footer-bottom .footer-copyright a:hover {
  text-decoration: underline;
}
#back-top:hover{
  opacity: 0.8;
}
#back-top{
  position: fixed;
  bottom: 210px;
  right: 8%;
  text-align: center;
  cursor: pointer;
  line-height: 56px;
  height: 56px;
  font-size: 16px;
  width: 56px;
  padding: 0;
  font-weight: 500;
  text-align: center;
  background: #e51515;
  color: #fff;
  -webkit-box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  border-radius: 100%;
  z-index: 999;
}
#back-top i{font-size: 16px;}
@media(max-width: 1400px){
  #back-top{
    bottom: 110px;
    right: 3%;
  }
}
@media(max-width: 1024px){
  #back-top{
    right: 30px;
    bottom: 65px;
    line-height: 46px;
    height: 46px;
    width: 46px;
  }
}

/* breadcrumb */
/* .common-home .breadcrumbs {
  display: none;
} */
.breadcrumbs {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.hd3 .breadcrumbs {	
  top: 67%;  
}
.breadcrumbs h1 {
  color: #fff;
  font-weight: 500;
  font-size: 48px;
  margin-bottom: 2px;
  margin-top: 0;
  text-transform: uppercase;
}
.breadcrumb{
  margin: 0;
  background: none;
  padding: 13px 0;
  text-transform: capitalize;
}
.breadcrumb li:before {
  margin: 0 5px;
}
.breadcrumb li a:hover{
  color: #e51515;
}
.breadcrumb li a{
  /* color: #fff; */
  font-size: 16px;
}
.breadcrumb li:last-child a {
  color: #e51515;
}
/* */

/* toolbar */
.category-image {
  margin-top: 30px;
}
.category-image img {
  border: none;
}
.text-refine {
  margin-top: 0;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.list-cate li {
  padding: 5px 0;
  font-size: 14px
}
#compare-total {
  line-height: 28px;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  font-size: 14px;
}
.toolbar-products {
  padding: 6px 21px;
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
  border: 2px solid #ebebeb;
  border-radius: 4px;
}
.toolbar-products.toolbar-bottom {
  margin-bottom:0;
  margin-top: 10px;
}
.toolbar-products .modes {
  display:inline-block;
  vertical-align: top;
  padding: 13px 0;
}
.toolbar-products .toolbar-amount {
  line-height: 35px;
  padding: 0;
  font-size: 12px;
  color: #8d8c8c;
  display: inline-block;
  vertical-align: top;
  padding: 5px 0;
  font-size: 14px;
}
#grid-view i,#list-view i {
  display: none;
}
.modes button {
  width: 20px;
  height: 18px;
  padding: 0;
  margin-right: 15px;
  font-size: 0;
  display: inline-block;
  vertical-align: top;
  border: none;
  border-radius: 0;
  box-shadow: none !important;
}
#grid-view {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/bkg_grid.png?6719724473154133038) center no-repeat;
}
#grid-view.selected,#grid-view:hover {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/bkg_grid_hover.png?6719724473154133038) center no-repeat;
}
#list-view {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/bkg_list.png?6719724473154133038) center no-repeat;
}
#list-view.selected,#list-view:hover {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/bkg_list_hover.png?6719724473154133038) center no-repeat;
}
.toolbar-products .sorter {
  margin-left: 45px;
  display: inline-block;
  vertical-align: top;
  padding: 5px 0;
}
.toolbar-products .limiter {
  display: inline-block;
  vertical-align: top;
  padding: 5px 0;
}
.toolbar-products .sorter label,.toolbar-products .limiter label {
  display:inline-block;
  vertical-align: top;
  line-height: 35px;
  margin: 0;
  color: #3e3e3e;
  font-size: 14px;
  margin-right: 8px;
}
.toolbar-products .sorter select, .toolbar-products .limiter select {
  padding: 0 15px;
  height: 35px;
  font-size: 13px;
  color: #898888;
  border-radius: 25px;
  display: inline-block;
  vertical-align: top;
  width: auto;
  border: 1px solid #e8e8e8;
  box-shadow: none;
}
.toolbar-products .pages {
  display:inline-block;
  vertical-align: top;
  padding: 8.5px 0;
}
.toolbar-products .pages ul {
  margin: 0;
  border-radius:0;
}
.toolbar-products .pages ul li {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px;
}
.toolbar-products .pages ul li a {
  display: block;
  color: #292929;
  font-size: 13px;
  font-weight: 400;
  padding: 0 4px;
  width: 28px;
  border-radius: 100%;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border: none;
  background: #e9e9e9;
}
.toolbar-products .pages ul li span {
  display: block;
  color: #fff;
  background: none;
  font-size: 13px;
  border: none;
  padding: 0 4px;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  line-height: 28px;
}
.toolbar-products .pages ul li.active span,.toolbar-products .pages ul li:hover a {
  color: #fff;
  background: #e51515;
  border: none;
}
/* category */
.category-img {
  display: block;
  margin-bottom: 20px;
  border-radius: 3px;
  overflow: hidden;
}
.category-img img {
  border: none;
}
.custom-products {
}
.custom-products .product-grid {
  margin-bottom: 30px;
}
.product-grid .product-des {
  display: none;
}
.product-list {margin-bottom: 30px;padding: 0 15px;}
.product-list .item .item-inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background: #efefef;
}
.product-list .col-image, .product-list .col-des {padding: 0;}
.product-list .col-des {background: #fff; width: 100%;}
.product-list .images-container {
}
.product-list .caption {padding: 11px 25px 25px 25px;}
.product-list .caption-top {
  padding-top: 10px;
}
.product-list .product-name {
}
.product-list .product-name a {
}
.product-list .item-inner .caption .price-box {
}
.product-list .price-box .price {
}
.product-list .product-des {border: 0;}
.product-list .button.btn-cart{
  width: auto;
  min-width: 230px;
  margin-top: 12px;
  float: left;
  margin-right: 20px;
}
.product-list .action-links {
  display: inline-block;
  vertical-align: top;
}
.product-list .item-inner .action-links button {
  margin-right: 6px;
  float: left;
  margin-top: 12px;
  margin-bottom: 0;
  height: 44px;
  line-height: 44px;
  width: 44px;
  background: #efefef;
}
.product-list .item-inner .action-links button:before {
  font-size: 16px;
}
.product-list .action-links button:last-child {
  margin-right: 0;
}
.product-list .item-inner .action-links button:hover {
  background: #e51515;
  color: #fff;
}
/* product detail */
.thumbnail, .img-thumbnail {
  -webkit-box-shadow:none;
  box-shadow: none;
  padding:0;
  border-radius:0;
  border: 0;
}
.thumbnails {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 10px;
}
.thumbnails .thumbnail{margin:0;}
.zoomContainer {
  top: 0px !important;
  left: 15px !important;
  right: 0px;
  bottom: 0px;
  width: 100% !important;
}
.zoomContainer .zoomLens {
  width: 100px !important;
  height: 100px !important;
}
.product-quickview .product-info-main .button-cart {
  min-width: auto;
}
.product-quickview .zoomContainer .zoomLens {
  display: none !important;
}
.product-quickview .thumbnails {
  pointer-events: none;
}
.product-quickview .ratings a {
  display: none;
}
.zoomContainer .zoomWindowContainer .zoomWindow {
  margin-left: 15px;
  border-color: #e51515 !important;
}
.image-additional-container {padding: 0 20px;}
.image-additional-container .thumbnail{
  margin: 0;
  opacity: 0.2;
  border-radius: 7px;
  overflow: hidden;
  display: block;
}
.image-additional-container .thumbnail:hover,
.image-additional-container .thumbnail.current-additional 
{
  opacity: 1;
}
.owl-style3  {
}
.owl-style3  .owl-item {
}
.owl-style3  .owl-carousel.owl-theme .owl-nav button.owl-prev {
  left: -30px !important;
  opacity: 1;
}
.owl-style3  .owl-carousel.owl-theme .owl-nav button.owl-next {
  right: -30px !important;
  opacity: 1;
}
.product-info-main .product-name {
  font-size: 20px;
  font-weight: 500;
  color: #181b23;
  text-transform: uppercase;
  margin: 0;
  /* padding-bottom: 14px; */
}
.product-info-main .product-code {
  padding-bottom: 14px;
  margin: 0;
  border-bottom: 1px solid #efefef;
}
.product-info-main .ratings{
  padding: 13px 0;
  border-bottom: 1px solid #efefef;
}
.product-info-main .rate-special {
  margin-top: 19px;
}
.product-info-main .price-box {
  margin-top: 19px;
  display: inline-block;
  vertical-align: top;
  padding: 2.5px 0;
}
.product-info-main .price-box .price {
}
.product-info-main .short-des {
  line-height: 24px;
  margin-top: 15px;
  padding-bottom: 17px;
}
#input-quantity2, #minus2, #plus2,
#Quantity, #minus, #plus {
  height: 21px;
  box-shadow: none;
  font-weight: 400;
  text-align: center;
  width: 20px;
  font-size: 12px;
  border-radius: 0;
  border: none;
  padding: 0;
  color: #a6a6a6;
}
#minus,#minus2 {
  position: absolute;
  bottom: 0;
  right: 14px;
}
#plus,#plus2 {
  position: absolute;
  top: 0px;
  right: 14px;
  border-bottom: 1px solid #ebebeb;
  height: 19px;
}
#input-quantity2,
#Quantity {
  width: 50px;
  min-width: auto;
  height: 40px;
  line-height: 50px;
  color: #292929;
  font-size: 14px;
}
.product-info-main .form-group {
  display:inline-block;
  vertical-align: top;
  width: 100%;
}
label[for='Quantity']{
  float: left;
  line-height: 32px;
  padding: 5px 0;
  margin-right: 30px;
  margin-bottom: 0;
  color: #363636;
}
.product-info-main .button-cart{
  float: left;
  margin-top: 15px;
  margin-right: 20px;
  padding: 0 40px;
  line-height: 42px;
  background: #efefef;
  color: #6c6f7a;
}
.product-info-main .button-cart:before {
  content: "";
  margin-right: 8px;
  margin-top: -3px;
  width: 16px;
  height: 16px;
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon-btn-cart.png?6719724473154133038) center no-repeat;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.product-info-main .button-cart:hover {
  background: #e51515;
  color: #fff;
}
.product-info-main .button-cart:hover:before {
  background: url(//cdn.shopify.com/s/files/1/3012/8606/t/6/assets/icon-btn-cart-hover.png?6719724473154133038) center no-repeat;
}
.product-info-main button.btn-wishlist,.product-info-main button.btn-compare {
  line-height: 42px;
  height: 42px;
  float: left;
  width: 42px;
  margin-top: 15px;
  background:#efefef;
}
.product-info-main button.btn-wishlist:before,.product-info-main button.btn-compare:before {
  font-size: 16px;
}
.product-info-main button.btn-wishlist {
  margin-right: 8px;
}
.product-info-main button.btn-wishlist:hover,.product-info-main button.btn-compare:hover {
  background: #e51515;
  color: #fff;
}
#product2.has-option,
#product.has-option{
  max-height: 300px;
  overflow: auto;
  border: 1px solid #e1e1e1;
  padding: 20px;
}
#product + div{
  margin: 20px 0;
}
.quantity-box {
  float: left;
  position: relative;
  padding-right: 25px;
  border: 1px solid #ebebeb;
  border-radius: 25px;
  padding: 0 15px;
  padding-right: 40px;
}
.product-info-main label[for='Quantity'], .product-info-main .quantity-box {
  margin-top: 15px;
  margin-right: 20px;
}
.product-info-main .box-options {
  padding: 18px 0;
}
.product-info-main ul.list-unstyled {
}
.product-info-main ul.list-unstyled li {
  display: block;
  line-height: 24px;
}
.product-info-main ul.list-unstyled li span,.product-info-main ul.list-unstyled li a {
  color: #e51515;
  margin-left: 5px;
}
.product-info-main ul.list-unstyled li a:hover {
  text-decoration: underline;
}
.product-info-detailed {
  margin-top: 60px;
}
.product-info-detailed .nav-tabs {
  margin-bottom: 0;
  border-top: 1px solid #ebebeb;
  padding-top: 48px;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.product-info-detailed .nav-tabs li {
  margin-bottom: 0;
  position: relative;
  margin-right: 50px;
}
.product-info-detailed .nav-tabs li:last-child {
  margin-right:0;
}
.product-info-detailed .nav-tabs li a {
  font-size: 14px;
  color: #292929 !important;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 0;
  border: none !important;
  padding: 8px 0;
  margin: 0;
  background: none;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}

.product-info-detailed .nav-tabs li.active a,.product-info-detailed .nav-tabs li a:hover {
  color: #e51515 !important;
  border-radius: 0;
}
.product-info-detailed .nav-tabs li.active a:before {
  opacity: 1;
}
.product-info-detailed .tab-content {
  line-height: 30px;
  padding-top: 30px;
}
#tab-description p {
  margin:0;
}
.bootstrap-datetimepicker-widget a[data-action]{
  padding: 0 !important;
}
.bootstrap-datetimepicker-widget td span:hover{
  background: none !important;
}
.bootstrap-datetimepicker-widget td span{
  height: 40px !important;
  line-height: 40px !important;
  margin: 0px !important;
}
.alert.alert-success{
  text-align: center;
  font-size: 14px;
  margin: 0;
}
.account-login .well{
  background: none;
  box-shadow: none;
}
#map-canvas{
  min-height: 400px;
  margin: 20px 0;
}
.information-contact form .required{
  margin-bottom: 20px;
}
.bootstrap-datetimepicker-widget a[data-action] {
  border: none;
  height: auto;
}
#content{
  min-height: 300px;
}
.btn-quickview span{
}
.btn-quickview i {
  display: none;
}
#cart .dropdown-menu {
  margin: 0;
  padding: 10px 30px 0 30px !important;
  width: 350px;
  right: -15px;
  left: auto;
  list-style: none none;
  border: 0;
  background: #fff;
  margin-top: 11px;
  box-sizing: border-box;
  top: 100%;
}
#cart .dropdown-menu:after,#cart .dropdown-menu:before,
.box-setting .dropdown-menu:after,.box-setting .dropdown-menu:before {
  border-bottom-style: solid;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
#cart .dropdown-menu:before,
.box-setting .dropdown-menu:before {
  border: 7px solid;
  border-color: transparent transparent #fff transparent;
  z-index: 99;
  right: 26px;
  top: -12px;
  opacity: 0;
}
.box-setting .dropdown-menu:before {
  right: 13px;
}
#cart .dropdown-menu:after,
.box-setting .dropdown-menu:after {
  border: 8px solid;
  border-color: transparent transparent #fff transparent;
  z-index: 98;
  right: 25px;
  top: -15px;
}
.box-setting .dropdown-menu:after {
  right: 12px;
}
#cart .total-price {
  font-size: 14px;
  font-weight: 500;
  margin-left: 17px;
  padding: 12px 0;
  display: inline-block;
  vertical-align: top;
}
#cart .cart-close {
  /* position: absolute; */
  top: 20px;
  right: 0;
  font-weight: 400;
}
#cart .cart-empty{
  padding-bottom: 25px;
  padding-top: 15px;
  margin: 0;
}

#cart .dropdown-menu > li:first-child .table > tbody > tr > td{
  border: 0;
}
#cart .dropdown-menu > li:last-child .table > tbody > tr > td.text-left {
  color: #3e3d43;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 0;
  text-transform: uppercase;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
#cart .dropdown-menu > li:last-child .table > tbody > tr > td.text-right {
  color: #292929;
  font-size: 14px;
  padding: 5px 0;
  font-weight: 500;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
#cart .dropdown-menu > li:last-child .table > tbody > tr:last-child > td.text-right {
  font-size: 18px;
  font-weight: 700;
}
#cart .dropdown-menu > li:first-child .table > tbody > tr {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  display: block;
}
#cart .table > tbody > tr > td{
  border: none;
  padding: 0;
}
#cart .cart-close button{
  background: none;
  color: #bababa;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 18px;
}
#cart .cart-close button:hover {
  color: #e51515;
}
#cart .cart-quantity{
  color: #969696;
  line-height: 20px;
  display: block;
  padding: 5px 0;
  font-size: 13px;
}
#cart .cart-price {
  font-size: 14px;
  color: #e51515;
  padding-top: 2px;
  display: block;
  font-weight: 500;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
#cart .cart-name {
  color: #363f4d;
  font-size: 14px;
  line-height: 18px;
  display: block;
}
#cart .cart-name:hover {
  color: #e51515;
}
#cart .cart-button{
  padding: 30px 0;
  margin: 0 -30px;
  background: #22232b;
}
#cart p.cart-button a:last-child{
}
#cart p.cart-button a{
  border: none;
  background: #fff;
  color: #3e3d43;
  line-height: 44px;
  text-align: center;
  padding: 0 35px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 30px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
  margin: 7px;
  font-family: "Oswald", "HelveticaNeue", "Helvetica Neue", sans-serif;
}
#cart p.cart-button a strong {
  font-weight: 400;
}
#cart p.cart-button a:hover{
  background: #e51515;
  color: #fff;
  -webkit-box-shadow: 0 0 20px 5px rgba(229,21,21,0.3);
  box-shadow: 0 0 20px 5px rgba(229,21,21,0.3)
}
#cart p.cart-button a:hover i{
  color: #fff;
}
#cart p.cart-button a i{
  padding-left: 5px;
  font-size: 10px;
  display: none;
}
#cart .dropdown-menu .info-item{
  padding-left: 10px;
}
#cart .dropdown-menu .has-scroll{
  max-height: 282px;
  overflow: auto;
  padding: 0 30px;
  margin: 0 -30px;
}
/*
*  STYLE 6
*/
#cart .dropdown-menu .has-scroll::-webkit-scrollbar-track
{
  background-color: #fff;
}
#cart .dropdown-menu .has-scroll::-webkit-scrollbar
{
  width: 5px;
  background-color: #fff;
}
#cart .dropdown-menu .has-scroll::-webkit-scrollbar-thumb
{
  background-color: #ccc;	
  background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent)
}
#cart .dropdown-menu tr td strong{
  color: #232323;
  font-weight: 500;
  float: left;
  padding: 2px 0;
  font-size: 12px;
  text-transform: uppercase;
}
.fluid-width >.container {
  width: auto;
  padding: 0 75px;
  max-width: 1920px;
  margin: auto;
}

@media(max-width: 1599px){
  /* .header-top {
    min-height: 400px;
  } */
  .fixedBanner {
    min-height: 530px;
  }
  .fluid-width >.container {
    padding: 0 30px;
  }
  /* .common-home header.hd2 #logo, .common-home header.hd3 #logo{
    padding: 40px 0;
  } */
}
/* responsive */
@media(max-width: 1349px){
  .owl-carousel.owl-theme .owl-nav button.owl-prev {
    left: 0;
  }
  .owl-carousel.owl-theme .owl-nav button.owl-next {
    right: 0;
  }
}
@media(max-width: 1199px){
  .fixedBanner {
    min-height: 400px;
  }
  .hd3 .breadcrumbs {
    top: 50%;
  }
  .menu-recent .aritcles-content {
    padding-top: 15px;
  }
  .ma-corporate-about1 {
    position: static;
    background: #181b23;
  }
  .hd3 .header-inner {
    position: relative;
    background: #181b23;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .static-top-aero1 .text .text1 {
    font-size: 13px;	
  }
  .static-top-aero1 .text .text2 {
    font-size: 18px;
  }
  .static-top-aero1 .text .text3 {
    font-size: 12px;
    margin-top: 15px;
  }
  .static-bottom-aero1 .text .text1 {
    font-size: 14px;
  }
  .static-bottom-aero1 .text .text2 {
    font-size: 24px;
    margin-top: 0;
  }
  .static-bottom-aero1.alt .text .text2 {
    font-size: 20px;
    letter-spacing: 3px;
  }
  .static-bottom-aero1 .text .text3 {
    font-size: 13px;
    margin-top: 10px;
    line-height: 20px
  }
  .static-bottom-aero1 .text .link {
    font-size: 12px;
    line-height: 36px;
    padding: 0 20px;
    padding-right: 45px;
    border-radius: 25px;
    margin-top: 32px;
  }
  .static-bottom-aero1 .text .link:after {
    font-size: 14px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
@media(max-width: 991px){
  .col-order {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .col-order #content {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    float: none;
  }
  .col-order #column-left {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    float: none;
  }
  #column-left,#column-right {
    margin-top: 30px;
  }
  .common-home header.hd2 .header-inner {
    position: relative;
    background: #22232b;
  }
  .common-home header.hd2 .header-inner:after {
    opacity: 1;
  }
  .common-home header.hd2 #search input {
    background: #22232b;
  }
  .common-home header.hd2 #logo {
    padding: 28px 0;
  }
  .static-welcome {
    margin-top: 44px;
  }
  .static-welcome .title {
    margin-bottom: 60px;
  }
  header {
    margin-bottom: 60px;
  }
  header .box-left {
    right: auto;
  }
  .logo-container {
    padding-left: 45px;
    position: static;
  }
  .hd3 #search {
    width: 100%;
    margin-top: 30px;
    position: static;
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .hd3 #search input {
    width: 100%;
  }
  .module-title {
    margin-top: 60px;
  }
  .static-top-aero1 {
    margin-top: 30px;
  }
  .module-title:before {
    width: 80%;
  }
  .module-title:after {
    width: 100%;
  }
  .bestseller-full {
    margin-top: 60px;
    padding-bottom: 60px;
  }
  .static-middle-aero1.alt {
    margin-top: 30px;
  }
  .newletter-subscribe-container {
    margin-top: 45px;
    padding-top: 60px;
  }
  .ma-corporate-about {
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .testimonial-container {
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 73px;
  }
  footer {
    margin-top: 60px;
  }
  footer .col4 {
    clear: left;
  }
  .footer-top {
    padding: 53px 0 15px 0;
  }
  ul.link-follow {
    margin: 0;
    float: none;
  }
  .footer-bottom .footer-copyright,.footer-payment{
    float: none;
    display: block;
    text-align: center;
    max-width: none;
    line-height: 1.2;
  }
  .newletter-popup,
  .b-modal{
    display: none !important;
  }
  .zoomContainer,
  .vermagemenu-container,
  #search-by-category .search-container .categories-container
  {
    display: none !important;
  }
}

@media(min-width: 768px) {
  .toolbar-products .modes {
    float: left;
    margin-right: 20px;
  }
  .toolbar-products .sorter,.toolbar-products .limiter ,.toolbar-products .pages {
    float: right;
  }
  .toolbar-products .toolbar-amount {
    float: left;
  }

}
@media(max-width: 767px){
  .link-compare {
    text-align: center;
  }
  .fluid-width >.container {
    padding: 0 15px;
  }
  .product-info-main {
    margin-top: 30px
  }
  .module-title {
    padding-right: 0 !important;
  }
  .static-bottom-aero1 .col{
    width: 100%;
    float: none;
  }
  #subscribe_email,#subscribe, .subscribe_email {
    width: 100%;
  }
  .ma-corporate-about, .ma-corporate-about1 {
    text-align: center;
  }
  .ma-corporate-about1 .col:nth-child(2) {
    border-top: 1px solid rgba(255,255,255,0.15);
    border-bottom: 1px solid rgba(255,255,255,0.15);
    border-left: 0;
    border-right: 0;
  }
  .testimonial-container .owl-carousel.owl-theme  .owl-dot {
    margin: 0 10px;
  }
  .testimonial-container .owl-carousel.owl-theme  .owl-dot:before,
  .testimonial-container .owl-carousel.owl-theme  .owl-dot:after {
    display: none !important;
  }
}
@media (max-width: 991px) {
  header.main-menu .box {
    position: relative;
    margin-bottom: 10px;
    left: auto;
    right: auto;
    display: inline-block;
    vertical-align: top;
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@media(max-width: 640px){ 
  .hd3 #logo {
    padding: 28px 0;
  }
  .hd3 .logo-container {
    display: block;
    padding: 0;
  }
  header.hd3 .box {
    position: relative;
    margin-bottom: 10px;
    left: auto;
    right: auto;
    display: inline-block;
    vertical-align: top;
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .hd3 #cart,header.hd3 .box-setting {
    float: none;
    display: inline-block;
    vertical-align: top;
  }
  header.hd3 .box-setting {
    margin: 0 0 0 10px;
  }
}
@media(max-width: 567px){ 
  .product-info-detailed .nav-tabs li {
    float: none;
    display: block;
    margin-right: 0;
  }

  .static-bottom-aero1 .text .text1 {
    font-size: 14px;
  }
  .static-bottom-aero1 .text .text2 {
    font-size: 24px;
    margin-top: 0;
  }
  .static-bottom-aero1 .text .text3 {
    font-size: 13px;
    margin-top: 10px;
    line-height: 20px
  }
  .static-bottom-aero1 .text .link {
    font-size: 12px;
    line-height: 36px;
    padding: 0 20px;
    padding-right: 45px;
    border-radius: 25px;
    margin-top: 32px;
  }
  .static-bottom-aero1 .text .link:after {
    font-size: 14px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
@media(min-width: 481px){
  .product-list .item .item-inner {
    /* display: table; */
    display: flex;
  }
  .product-list .col-image, .product-list .col-des {
    display: table-cell;
    vertical-align: middle;
    float: none;
    overflow: hidden;
  }
}
@media(max-width: 479px){

  .ma-corporate-about .block-wrapper:after {
    right: -15px;
  }
  .toolbar-products .sorter,.toolbar-products .limiter {
    display: block;
    margin: 0;
  }
  .logo-container {
    max-width: 50%;
  }
  .hd3 .logo-container {
    max-width: 100%;
  }
  .header-inner {
    padding: 0 15px;
  }
  header .box-setting {
    margin: 0;
  }

  #search .search-content {
    top: 100%;
    right: -75px;
    margin-top: 15px;
  }
  #cart {
    margin: 0 5px;
  }
  #cart .dropdown-menu {
    width: 250px;
    right: -30px;
  }
  #cart .dropdown-menu::before {
    right: 41px;
  }
  #cart .dropdown-menu::after {
    right: 40px;
  }
  header .box-setting .dropdown-menu {
    right: -60px;
  }
  .box-setting .dropdown-menu:before {
    right: 75px;
  }
  .box-setting .dropdown-menu:after {
    right: 74px;
  }
  .product-info-main .button-cart {
    min-width: auto;
  }
  .menu-recent .articles-image {
    width: 100%;
    float: none;
  }
  .menu-recent .aritcles-content {
    padding-left: 0;
    float: none;
    width: 100%;
  }
  .static-welcome .title h2 {
    font-size: 90px;
  }
  .static-welcome .content .image {
    float: none;
    width: 100%;
    padding: 0;
  }
  .static-welcome .content .text {
    padding: 0 15px;
  }
  .module-title {
    margin-top: 40px;
  }
  .module-title h2 {
    padding: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .module-title h2:before,.module-title h2:after,
  .module-title:before,.module-title:after {
    display:none;
  }
  .testimonial-box {
    padding: 0 50px;
  }
  .static-bottom-aero1 .text .text1 {
    font-size: 12px;
  }
  .static-bottom-aero1 .text .text2 {
    font-size: 18px;
    margin-top: 0;
    letter-spacing: 2px;
  }
  .static-bottom-aero1 .text .text3 {
    font-size: 12px;
    margin-top: 5px;
    line-height:18px
  }
  .static-bottom-aero1 .text .link {
    font-size: 10px;
    line-height: 36px;
    padding: 0 20px;
    padding-right: 45px;
    border-radius: 25px;
    margin-top: 15px;
  }
  .static-bottom-aero1 .text .link:after {
    font-size: 14px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .breadcrumbs h1 {
    font-size: 35px;
  }
}
/*Fix products-grid */
@media (min-width: 1200px){
  .custom-products .product-grid.three-items:nth-child(3n+1),
  .custom-products .product-grid.four-items:nth-child(4n+1),
  .custom-products .product-grid.two-items:nth-child(2n+1)
  {  clear: left;} 
}
@media (max-width: 1199px) and (min-width: 992px){
  .custom-products .product-grid.three-items:nth-child(3n+1), 
  .custom-products .product-grid.four-items:nth-child(4n+1),
  .custom-products .product-grid.two-items:nth-child(2n+1)
  {clear: left;}
}
@media (max-width: 991px) and (min-width: 481px){
  .custom-products .product-grid:nth-child(2n+1) {clear: left;}
}
@media (max-width: 480px) and (min-width: 0px){
  .custom-products .product-grid:nth-child(1n+1) {clear: left;}
  .custom-products .product-grid{width: 100%;}  
}
/*Fix products-no-slider */
.two_items,.three_items,.four_items,.six_items {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .six_items:nth-child(6n+1),
  .four_items:nth-child(4n+1),
  .three_items:nth-child(3n+1),
  .two_items:nth-child(2n+1)
  {  clear: left;} 
} 

/* full width */
.full-width{
  margin: 0 !important;
  padding: 0;
}
.full-width > .container{
  width: 100% !important;
  padding: 0;
}
.full-width > .container  .sub-row,
.full-width > .container  > .row{
  margin: 0
}
.full-width > .container  .sub-row > div,
.full-width > .container .row > .main-col{
  padding: 0;
}
.special-row{
  background: #f8f8f8;
  padding-top: 60px;
}