.flexslider {
  margin: 0 !important;
}

.flex-control-nav {
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.flexslider:hover .flex-direction-nav li {
  width: 60px;
  height: 60px;
  margin: -30px 0 0;
  display: block;
  position: absolute;
  top: 50%;
  cursor: pointer;
  font-size: 0;
  z-index: 9;
  background: rgba(255,255,255,0.3);
  border-radius: 100%;
  line-height: 60px;
  text-align: center;
  color: #fff;
}

.flex-direction-nav li:hover {
  background: #e51515 !important;
  -webkit-box-shadow: 0 0 20px 10px rgb(229 21 21 / 30%);
  box-shadow: 0 0 20px 10px rgb(229 21 21 / 30%);
}

.flex-nav-prev {
  /* opacity: 0; */
  left: 10px;
  transition: all 0.3s ease-out;
}

.flex-nav-next {
  /* opacity: 0; */
  transition: all 0.3s ease-out;
  right: 10px;
}

.flex-nav-next a {
  right: 22px !important;
}

.flex-direction-nav a {
  height: 60px;
  margin: -29px 0 0;
}

.flex-direction-nav a:before {
  font-size: 20px;
}

.flex-control-paging li a {
  background: #666;
}

.flex-control-paging li a.flex-active {
  background-color: red;
}

.home-banner {
  overflow: hidden;
  border: none;
}

.banner7-content .title1 {
  font-size: 14px;
  line-height: 1;
  letter-spacing: 3px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: 'Oswald', sans-serif;
}

.banner7-content .sub-title {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  line-height: 1;
  /* letter-spacing: 20px; */
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 0;
  font-family: 'Oswald', sans-serif;
}

.banner7-content .banner7-des p {
  font-size: 16px;
  /* margin: auto; */
  padding: 0;
  color: #fff;
  line-height: 24px;
  letter-spacing: 0.25px;
  max-width: 650px;
}

.banne-content-wrap {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  left: initial;
}

@media(min-width: 768px){
  .banne-content-wrap {
    text-align: left;
    width: 50%;
    left: 10rem;
  }
  .banner7-content .sub-title {
    font-size: 48px;
  }
}

@media(min-width: 1200px){
  .home-banner {
    height: 70vh;
  }
}

@media(min-width: 1200px){
  .banne-content-wrap {
    left: 0;
    right: 0;
    margin: auto;
  }
}

.banner7-readmore button {
  background: #efefef;
  color: #6c6f7a;
}
.banner7-readmore {
  margin-top: 30px;
}